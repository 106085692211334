import React from "react";
import LoadingMessages from "../common/LoadingMessages";

function InitialLoadingMessages() {
  //
  const messages = [
    "Getting your travel plan",
    "Getting your travel plan",
    "Getting your travel plan",
  ];
  const messageDuration = 2000;
  const dotsCyclePerDuration = 2;

  return (
    <LoadingMessages
      messages={messages}
      messageDuration={messageDuration}
      dotsCyclePerDuration={dotsCyclePerDuration}
    />
  );
}

export default InitialLoadingMessages;
