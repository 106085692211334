import React, { Component } from "react";
import styles from "./AddNotification.module.scss";
import { ReactComponent as Email } from "../assets/round-email-24px.svg";
import { ReactComponent as Phone } from "../assets/round-phone_iphone-24px.svg";
import { ReactComponent as Check } from "../assets/round-check-24px.svg";
import userLocalDataService from "./../../services/data/userLocalDataService";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import "../../customStyles/reactPhone.scss";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../Context/ThemeContext";

class AddNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      country: "",
      user: {
        mobilePhoneNumber: "",
        emailAddress: "",
        isDisruptionChecked: true,
        isMonthBeforeChecked: true,
        isWeekBeforeChecked: true,
        isDayBeforeChecked: true,
        isHourBeforeChecked: true,
      },
      travelForecast: {
        status: [
          "NotifyDisruption",
          "NotifyMonthBefore",
          "NotifyWeekBefore",
          "NotifyDayBefore",
          "NotifyHourBefore",
        ],
        useWhatsApp: true,
        notificationAutomaticApproval: "",
      },
      complete: false,
      submitted: false,
      isSubmitButtonHovered: false,
    };
  }

  componentDidMount = () => {
    if (this.props.auth.isAuthenticated()) {
      const apimUser = userLocalDataService.getAPIMUser();
      var user = {
        ...this.state.user,
        emailAddress: apimUser.identityEmail, //Set initial email address to be the same as the identity email
        identityEmail: apimUser.identityEmail,
      };
      this.setState({ user: user });
    }

    if (
      this.props.organisation !== null ||
      this.props.organisation !== undefined
    ) {
      var forecast = this.state.travelForecast;
      forecast.organisationId =
        this.props.organisation != null
          ? this.props.organisation.organisationId
          : null;
      this.setState({ travelForecast: forecast });
    }

    axios.get("https://geolocation-db.com/json/").then((response) => {
      this.setState({ country: response.data.country_code });
    });

    this.nameInput.focus();
  };

  handleEmailChange = (event) => {
    var user = this.state.user;
    user.emailAddress = event.target.value;
    this.setState({ user: user });
  };

  handlePhoneChange = (event) => {
    var user = this.state.user;
    user.mobilePhoneNumber = event;
    this.setState({ user: user });
  };

  handleCountryChange = (event) => {
    var country = this.state.country;
    country = event;
    this.setState({ country: country });
  };

  handleDisruptionChange = () => {
    let user = { ...this.state.user };
    user.isDisruptionChecked = !this.state.user.isDisruptionChecked;
    const travelForecast = this.state.travelForecast;
    if (user.isDisruptionChecked)
      travelForecast.status.push("NotifyDisruption");
    else
      travelForecast.status = travelForecast.status.filter(
        (e) => e !== "NotifyDisruption"
      );
    this.setState({ user, travelForecast });
  };

  handleMonthBeforeChange = () => {
    let user = { ...this.state.user };
    user.isMonthBeforeChecked = !this.state.user.isMonthBeforeChecked;
    let travelForecast = this.state.travelForecast;
    if (user.isMonthBeforeChecked)
      travelForecast.status.push("NotifyMonthBefore");
    else
      travelForecast.status = travelForecast.status.filter(
        (e) => e !== "NotifyMonthBefore"
      );
    this.setState({ user, travelForecast });
  };

  handleWeekBeforeChange = () => {
    let user = { ...this.state.user };
    user.isWeekBeforeChecked = !this.state.user.isWeekBeforeChecked;
    let travelForecast = this.state.travelForecast;
    if (user.isWeekBeforeChecked)
      travelForecast.status.push("NotifyWeekBefore");
    else
      travelForecast.status = travelForecast.status.filter(
        (e) => e !== "NotifyWeekBefore"
      );
    this.setState({ user, travelForecast });
  };

  handleDayBeforeChange = () => {
    let user = { ...this.state.user };
    user.isDayBeforeChecked = !this.state.user.isDayBeforeChecked;
    const travelForecast = this.state.travelForecast;
    if (user.isDayBeforeChecked) travelForecast.status.push("NotifyDayBefore");
    else
      travelForecast.status = travelForecast.status.filter(
        (e) => e !== "NotifyDayBefore"
      );
    this.setState({ user, travelForecast });
  };

  handleHourBeforeChange = () => {
    let user = { ...this.state.user };
    user.isHourBeforeChecked = !this.state.user.isHourBeforeChecked;
    const travelForecast = this.state.travelForecast;
    if (user.isHourBeforeChecked)
      travelForecast.status.push("NotifyHourBefore");
    else
      travelForecast.status = travelForecast.status.filter(
        (e) => e !== "NotifyHourBefore"
      );
    this.setState({ user, travelForecast });
  };

  handleUseWhatsAppChange = () => {
    this.setState((prevState) => ({
      travelForecast: {
        ...prevState.travelForecast,
        useWhatsApp: !prevState.travelForecast.useWhatsApp,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true }, function () {
      if (
        this.state.user.mobilePhoneNumber ||
        (this.state.user.emailAddress &&
          (this.state.user.isDayBeforeChecked ||
            this.state.user.isWeekBeforeChecked ||
            this.state.user.isDisruptionChecked))
      ) {
        document.activeElement.blur();
        if (
          this.state.user.emailAddress !== "" ||
          this.state.user.mobilePhoneNumber !== ""
        ) {
          this.props.callbackFromParent(
            this.state.user,
            this.state.travelForecast
          );
        }
      }
    });
  };

  render() {
    const { user, submitted, value, country, travelForecast } = this.state;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={styles["AddNotification"]}>
            <section>
              <form
                action="#"
                onSubmit={this.handleSubmit}
                id="addnotification"
              >
                <div>
                  <div>
                    <input type="hidden" value="" />
                  </div>
                  <div>
                    <input type="hidden" value="" />
                  </div>
                  <div className={styles["form-item"]}>
                    <div className="inspectletIgnore">
                      <div className={styles["form-item-content-container"]}>
                        <label
                          className={styles["form-item-label"]}
                          htmlFor="email"
                        >
                          <FormattedMessage id="emailTxt" />
                        </label>
                        <div dir="ltr">
                          <div className={styles["form-item-control"]}>
                            <div className={styles["form-icon-outer-sub"]}>
                              <div className={styles["form-icon-inner-sub"]}>
                                <div
                                  className={
                                    styles["form-icon-outer-container"]
                                  }
                                >
                                  <div
                                    className={
                                      styles["form-icon-inner-container"]
                                    }
                                  >
                                    <Email className={styles["Email"]} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles["form-input-container"]}>
                              <FormattedMessage id="emailTxt">
                                {(placeholder) => (
                                  <input
                                    ref={(input) => {
                                      this.nameInput = input;
                                    }}
                                    onChange={this.handleEmailChange}
                                    className={styles["form-input"]}
                                    type="email"
                                    id="emailAddress"
                                    name="emailAddress"
                                    placeholder={placeholder}
                                    value={user.emailAddress}
                                  />
                                )}
                              </FormattedMessage>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {submitted &&
                      !user.mobilePhoneNumber &&
                      !user.emailAddress && (
                        <div className={styles["error"]}>
                          <FormattedMessage id="emailOrPhoneRequired" />
                        </div>
                      )}
                  </div>
                  <div className={styles["form-item"]}>
                    <div className="inspectletIgnore">
                      <div className={styles["form-item-content-container"]}>
                        <label
                          className={styles["form-item-label"]}
                          htmlFor="tel"
                        >
                          <FormattedMessage id="phoneNumber" />
                        </label>
                        <div dir="ltr">
                          <div className={styles["form-item-control"]}>
                            <div className={styles["form-icon-outer-sub"]}>
                              <div className={styles["form-icon-inner-sub"]}>
                                <div
                                  className={
                                    styles["form-icon-outer-container"]
                                  }
                                >
                                  <div
                                    className={
                                      styles["form-icon-inner-container"]
                                    }
                                  >
                                    <Phone className={styles["Phone"]} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles["form-input-container"]}>
                              {value ? (
                                isPossiblePhoneNumber(value) ? undefined : (
                                  <FormattedMessage id="invalidNumber">
                                    {(placeholder) => (
                                      <PhoneInput
                                        withCountryCallingCode
                                        international
                                        defaultCountry={country}
                                        className={styles["form-input"]}
                                        placeholder="Mobile number (optional)"
                                        onChange={this.handlePhoneChange}
                                        onCountryChange={
                                          this.handleCountryChange
                                        }
                                        focusInputOnCountrySelection={true}
                                        id="mobilePhoneNumber"
                                        value={value}
                                        error={placeholder}
                                      />
                                    )}
                                  </FormattedMessage>
                                )
                              ) : (
                                <FormattedMessage id="mobileNumOptional">
                                  {(placeholder) => (
                                    <PhoneInput
                                      withCountryCallingCode
                                      international
                                      defaultCountry={country}
                                      className={styles["form-input"]}
                                      placeholder="Mobile number (optional)"
                                      onChange={this.handlePhoneChange}
                                      onCountryChange={this.handleCountryChange}
                                      focusInputOnCountrySelection={true}
                                      id="mobilePhoneNumber"
                                      value={value}
                                      error={placeholder}
                                    />
                                  )}
                                </FormattedMessage>
                              )}

                              {/* <input
                            onChange={this.handlePhoneChange}
                            className={styles["form-input"]}
                            type="tel"
                            id="mobilePhoneNumber"
                            name="mobilePhoneNumber"
                            placeholder="Mobile number (optional)"
                            value={user.mobilePhoneNumber}
                          /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles["option-item"]}>
                    <div
                      className={styles["option-item-label-outer-container"]}
                    >
                      <div
                        className={styles["option-item-label-inner-container"]}
                      >
                        <label className={styles["option-item-label"]}>
                          <div
                            className={
                              styles["option-item-title-outer-container"]
                            }
                          >
                            <div
                              className={
                                styles["option-item-title-inner-container"]
                              }
                            >
                              <div className={styles["option-item-title"]}>
                                <span
                                  className={styles["option-item-title-span"]}
                                >
                                  <FormattedMessage id="useWhatsApp" />
                                </span>
                              </div>
                            </div>
                            <div
                              className={
                                styles["option-item-control-outer-container"]
                              }
                            >
                              <div
                                className={
                                  styles["option-item-control-inner-container"]
                                }
                              >
                                <span
                                  className={styles["option-item-control-span"]}
                                >
                                  <input
                                    checked={travelForecast.useWhatsApp}
                                    onChange={this.handleUseWhatsAppChange}
                                    type="checkbox"
                                    className={
                                      styles["option-item-control-input"]
                                    }
                                  />
                                  <span
                                    style={{
                                      background: travelForecast.useWhatsApp
                                        ? theme.theme.primaryColour
                                        : "white",
                                      borderColor: travelForecast.useWhatsApp
                                        ? theme.theme.primaryColour
                                        : "white",
                                    }}
                                    className={
                                      travelForecast.useWhatsApp
                                        ? styles[
                                            "option-item-control-icon-active"
                                          ]
                                        : styles[
                                            "option-item-control-icon-inactive"
                                          ]
                                    }
                                  >
                                    {travelForecast.useWhatsApp && (
                                      <span
                                        className={
                                          styles[
                                            "option-item-control-icon-inner-span"
                                          ]
                                        }
                                      >
                                        <Check className={styles["Check"]} />
                                      </span>
                                    )}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={styles["form-button-container"]}>
                    <button
                      style={{
                        background: !this.state.isSubmitButtonHovered
                          ? theme.theme.primaryColour
                          : theme.theme.hoverColour,
                      }}
                      onMouseEnter={() => {
                        this.setState({ isSubmitButtonHovered: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({ isSubmitButtonHovered: false });
                      }}
                      type="submit"
                      form="addnotification"
                      className={styles["form-button"]}
                      aria-label="Send your travel plan"
                    >
                      <span className={styles["form-button-span"]}>
                        <FormattedMessage id="confirmDetails" />
                      </span>
                    </button>
                  </div>
                  <section>
                    <div>
                      <div>
                        <div>
                          <section>
                            <div className={styles["sub"]}>
                              <div className={styles["content"]}>
                                <section>
                                  <div
                                    className={styles["collection-container"]}
                                  >
                                    <div
                                      className={styles["item-outer-container"]}
                                    >
                                      <div
                                        className={
                                          styles["item-inner-container"]
                                        }
                                      >
                                        <div
                                          className={
                                            styles["subtitle-container"]
                                          }
                                        >
                                          <section>
                                            <h3
                                              tabIndex="-1"
                                              className={styles["h3"]}
                                            >
                                              <div
                                                className={styles["subtitle"]}
                                              >
                                                <FormattedMessage id="privacyTitle" />
                                              </div>
                                            </h3>
                                          </section>
                                        </div>
                                        <div
                                          className={
                                            styles[
                                              "description-outer-container"
                                            ]
                                          }
                                        >
                                          <div
                                            className={
                                              styles[
                                                "description-inner-container"
                                              ]
                                            }
                                          >
                                            <div
                                              className={styles["description"]}
                                            >
                                              {this.props.consent !== "" &&
                                              this.props.consent !==
                                                undefined ? (
                                                this.props.consent
                                              ) : (
                                                <FormattedMessage id="notificationDescription" />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={styles["break-container"]}
                                      >
                                        <div className={styles["break"]} />
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </form>
            </section>
            <section>
              <div className={styles["options-subtitle-container"]}>
                <div className={styles["options-subtitle"]}>
                  <FormattedMessage id="pleaseUpdate" />
                </div>
              </div>
              <div className={styles["option-item"]}>
                <div className={styles["option-item-label-outer-container"]}>
                  <div className={styles["option-item-label-inner-container"]}>
                    <label className={styles["option-item-label"]}>
                      <div
                        className={styles["option-item-title-outer-container"]}
                      >
                        <div
                          className={
                            styles["option-item-title-inner-container"]
                          }
                        >
                          <div className={styles["option-item-title"]}>
                            <span className={styles["option-item-title-span"]}>
                              <FormattedMessage id="travelChanges" />
                            </span>
                          </div>
                        </div>
                        <div
                          className={
                            styles["option-item-control-outer-container"]
                          }
                        >
                          <div
                            className={
                              styles["option-item-control-inner-container"]
                            }
                          >
                            <span
                              className={styles["option-item-control-span"]}
                            >
                              <input
                                checked={user.isDisruptionChecked}
                                onChange={this.handleDisruptionChange}
                                type="checkbox"
                                className={styles["option-item-control-input"]}
                              />
                              <span
                                style={{
                                  background: user.isDisruptionChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                  borderColor: user.isDisruptionChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                }}
                                className={
                                  user.isDisruptionChecked
                                    ? styles["option-item-control-icon-active"]
                                    : styles[
                                        "option-item-control-icon-inactive"
                                      ]
                                }
                              >
                                {user.isDisruptionChecked && (
                                  <span
                                    className={
                                      styles[
                                        "option-item-control-icon-inner-span"
                                      ]
                                    }
                                  >
                                    <Check className={styles["Check"]} />
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles["option-item"]}>
                <div className={styles["option-item-label-outer-container"]}>
                  <div className={styles["option-item-label-inner-container"]}>
                    <label className={styles["option-item-label"]}>
                      <div
                        className={styles["option-item-title-outer-container"]}
                      >
                        <div
                          className={
                            styles["option-item-title-inner-container"]
                          }
                        >
                          <div className={styles["option-item-title"]}>
                            <span className={styles["option-item-title-span"]}>
                              <FormattedMessage id="beforeMyJourney" />
                            </span>
                          </div>
                        </div>
                        <div
                          className={
                            styles["option-item-control-outer-container"]
                          }
                        >
                          <div
                            className={
                              styles["option-item-control-inner-container"]
                            }
                          >
                            <span
                              className={styles["option-item-control-span"]}
                            >
                              <input
                                checked={user.isMonthBeforeChecked}
                                onChange={this.handleMonthBeforeChange}
                                type="checkbox"
                                className={styles["option-item-control-input"]}
                              />
                              <span
                                style={{
                                  background: user.isMonthBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                  borderColor: user.isMonthBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                }}
                                className={
                                  user.isMonthBeforeChecked
                                    ? styles["option-item-control-icon-active"]
                                    : styles[
                                        "option-item-control-icon-inactive"
                                      ]
                                }
                              >
                                {user.isMonthBeforeChecked && (
                                  <span
                                    className={
                                      styles[
                                        "option-item-control-icon-inner-span"
                                      ]
                                    }
                                  >
                                    <Check className={styles["Check"]} />
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles["option-item"]}>
                <div className={styles["option-item-label-outer-container"]}>
                  <div className={styles["option-item-label-inner-container"]}>
                    <label className={styles["option-item-label"]}>
                      <div
                        className={styles["option-item-title-outer-container"]}
                      >
                        <div
                          className={
                            styles["option-item-title-inner-container"]
                          }
                        >
                          <div className={styles["option-item-title"]}>
                            <span className={styles["option-item-title-span"]}>
                              <FormattedMessage id="weekBefore" />
                            </span>
                          </div>
                        </div>
                        <div
                          className={
                            styles["option-item-control-outer-container"]
                          }
                        >
                          <div
                            className={
                              styles["option-item-control-inner-container"]
                            }
                          >
                            <span
                              className={styles["option-item-control-span"]}
                            >
                              <input
                                checked={user.isWeekBeforeChecked}
                                onChange={this.handleWeekBeforeChange}
                                type="checkbox"
                                className={styles["option-item-control-input"]}
                              />
                              <span
                                style={{
                                  background: user.isWeekBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                  borderColor: user.isWeekBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                }}
                                className={
                                  user.isWeekBeforeChecked
                                    ? styles["option-item-control-icon-active"]
                                    : styles[
                                        "option-item-control-icon-inactive"
                                      ]
                                }
                              >
                                {user.isWeekBeforeChecked && (
                                  <span
                                    className={
                                      styles[
                                        "option-item-control-icon-inner-span"
                                      ]
                                    }
                                  >
                                    <Check className={styles["Check"]} />
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles["option-item"]}>
                <div className={styles["option-item-label-outer-container"]}>
                  <div className={styles["option-item-label-inner-container"]}>
                    <label className={styles["option-item-label"]}>
                      <div
                        className={styles["option-item-title-outer-container"]}
                      >
                        <div
                          className={
                            styles["option-item-title-inner-container"]
                          }
                        >
                          <div className={styles["option-item-title"]}>
                            <span className={styles["option-item-title-span"]}>
                              <FormattedMessage id="dayBefore" />
                            </span>
                          </div>
                        </div>
                        <div
                          className={
                            styles["option-item-control-outer-container"]
                          }
                        >
                          <div
                            className={
                              styles["option-item-control-inner-container"]
                            }
                          >
                            <span
                              className={styles["option-item-control-span"]}
                            >
                              <input
                                checked={user.isDayBeforeChecked}
                                onChange={this.handleDayBeforeChange}
                                type="checkbox"
                                className={styles["option-item-control-input"]}
                              />
                              <span
                                style={{
                                  background: user.isDayBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                  borderColor: user.isDayBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                }}
                                className={
                                  user.isDayBeforeChecked
                                    ? styles["option-item-control-icon-active"]
                                    : styles[
                                        "option-item-control-icon-inactive"
                                      ]
                                }
                              >
                                {user.isDayBeforeChecked && (
                                  <span
                                    className={
                                      styles[
                                        "option-item-control-icon-inner-span"
                                      ]
                                    }
                                  >
                                    <Check className={styles["Check"]} />
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles["option-item"]}>
                <div className={styles["option-item-label-outer-container"]}>
                  <div className={styles["option-item-label-inner-container"]}>
                    <label className={styles["option-item-label"]}>
                      <div
                        className={styles["option-item-title-outer-container"]}
                      >
                        <div
                          className={
                            styles["option-item-title-inner-container"]
                          }
                        >
                          <div className={styles["option-item-title"]}>
                            <span className={styles["option-item-title-span"]}>
                              <FormattedMessage id="hourBefore" />
                            </span>
                          </div>
                        </div>
                        <div
                          className={
                            styles["option-item-control-outer-container"]
                          }
                        >
                          <div
                            className={
                              styles["option-item-control-inner-container"]
                            }
                          >
                            <span
                              className={styles["option-item-control-span"]}
                            >
                              <input
                                checked={user.isHourBeforeChecked}
                                onChange={this.handleHourBeforeChange}
                                type="checkbox"
                                className={styles["option-item-control-input"]}
                              />
                              <span
                                style={{
                                  background: user.isHourBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                  borderColor: user.isHourBeforeChecked
                                    ? theme.theme.primaryColour
                                    : "white",
                                }}
                                className={
                                  user.isHourBeforeChecked
                                    ? styles["option-item-control-icon-active"]
                                    : styles[
                                        "option-item-control-icon-inactive"
                                      ]
                                }
                              >
                                {user.isHourBeforeChecked && (
                                  <span
                                    className={
                                      styles[
                                        "option-item-control-icon-inner-span"
                                      ]
                                    }
                                  >
                                    <Check className={styles["Check"]} />
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {submitted &&
                !user.isHourBeforeChecked &&
                !user.isDayBeforeChecked &&
                !user.isWeekBeforeChecked &&
                user.isMonthBeforeChecked &&
                !user.isDisruptionChecked && (
                  <div className={styles["error"]}>
                    <FormattedMessage id="updatePrefence" />
                  </div>
                )}
            </section>
            <section>
              <a
                href="https://yousmartthing.com/terms"
                target="_blank"
                className={styles["a"]}
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacyPolicyLink" />
              </a>
              .
              <div className={styles["options-divider-container"]}>
                <div className={styles["options-divider"]} />
              </div>
            </section>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default AddNotification;
