import MarkerOption from "./MarkerOption";
import styles from "./MarkerOptions.module.scss";

const MarkerOptions = ({
  markerOptions,
  markerOptionsAction,
  isTrafficForecastAvailable,
  trafficForecast,
  switchTrafficForecast,
}) => {
  if (!markerOptions) {
    return null;
  }

  const organizeByHeading = (array) => {
    const arr = array.map((element, index) => {
      element.index = index;
      if (!element.heading) {
        element.heading = "";
      }
      return element;
    });

    let organizedArr = [];
    let headings = new Set();

    // Get unique headings.
    for (let item of arr) {
      if (item.hasOwnProperty("heading")) {
        headings.add(item.heading);
      }
    }

    // Organize elements by heading.
    for (let heading of headings) {
      let subArr = [];
      for (let item of arr) {
        if (item.hasOwnProperty("heading") && item.heading === heading) {
          subArr.push(item);
        }
      }
      organizedArr.push(subArr);
    }

    return organizedArr;
  };

  const organisedMarkers = organizeByHeading(markerOptions);

  return (
    <div className={styles["marker-group"]}>
      {organisedMarkers.map((markerOptions, organisedMarkersIndex) => {
        return markerOptions.map(
          (markerOption, index) =>
            markerOption.marker !== undefined &&
            markerOption.marker.icon !== undefined && (
              <div key={"MarkerOption" + organisedMarkersIndex + "-" + index}>
                {index === 0 ? (
                  <div style={{ marginBottom: "10px" }}>
                    <b className={styles["modal-subtitle"]}>
                      {markerOption.heading}
                    </b>
                  </div>
                ) : (
                  ""
                )}
                <div
                  key={markerOption.index}
                  onClick={() => {
                    markerOptionsAction(markerOption.index);
                  }}
                >
                  <MarkerOption
                    index={index}
                    param={markerOption.marker.icon}
                    active={markerOptions[index].isFocused}
                    loading={markerOption.isLoading}
                    text={markerOption.marker.name}
                  />
                </div>
              </div>
            )
        );
      })}
      {isTrafficForecastAvailable && (
        <div
          key={markerOptions.length}
          onClick={() => {
            switchTrafficForecast();
          }}
        >
          <MarkerOption
            index={markerOptions.length}
            param={"exclamation"}
            active={trafficForecast}
            loading={false}
            text={"Disruptions"}
          />
        </div>
      )}
    </div>
  );
};

export default MarkerOptions;
