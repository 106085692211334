import React, { Component } from "react";
import styles from "./Menu.module.scss";
import { ReactComponent as Close } from "../../assets/round-close-24px.svg";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  close = () => {
    this.props.showMenu();
  };

  isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  showEditProfile = () => {
    this.close();
    this.props.showProfile();
    this.props.showEditProfile();
  };

  showProfile = () => {
    this.close();
    this.props.showProfile();
  };

  login = () => {
    this.props.handleLoginOperation();
  };

  logout = () => {
    let logout = window.confirm("Do you want to log out from Travel Assistant");
    if (logout) this.props.logout();
  };

  setHomePage = () => {};

  render() {
    return (
      <div id="menu" className={styles["Modal"]}>
        <div className={styles["content"]} role="dialog">
          <div className={styles["header"]}>
            <div className={styles["button-container"]}>
              <button
                type="button"
                className={styles["button"]}
                onClick={this.close}
              >
                <Close className={styles["Close"]} />
              </button>
            </div>
            {!this.props.organisation?.isMobileMenuEnabled && (
              <>
                <div className={styles["title-container"]}>
                  <div className={styles["title"]}>
                    <span>Menu</span>
                  </div>
                </div>
                <div className={styles["action-container"]}>
                  <div className={styles["action"]} />
                </div>
              </>
            )}
          </div>
          {this.props.organisation?.isMobileMenuEnabled && (
            <img
              height={80}
              width={80}
              style={{
                marginTop: "-35px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={this.props.organisation?.desktopHeader?.imageUrl}
              alt="new"
            />
          )}
          {this.props.organisation?.isMobileMenuEnabled ? (
            <div id="menu-authenticated">
              <ul>
                {this.props.organisation?.mobileMenu.map((link, index) => (
                  <span>
                    <p>
                      {link.key !== "profile" ? (
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles["menu-link-text"]}
                        >
                          {link.name}
                        </a>
                      ) : this.props.auth.isAuthenticated() === true ? (
                        <a
                          onClick={() => this.showProfile()}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles["menu-link-text"]}
                        >
                          {link.name}
                        </a>
                      ) : (
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles["menu-link-text"]}
                        >
                          {link.name}
                        </a>
                      )}
                    </p>
                  </span>
                ))}
              </ul>
            </div>
          ) : this.props.auth.isAuthenticated() === false ? (
            this.props.thirdPartyAuthenticated ? (
              <div id="menu-authenticated">
                <ul>
                  {/* <p
                onClick={() => this.logout()}
                className={styles["menu-link-text"]}
              >
                Sign out
              </p> */}

                  <p
                    onClick={() => this.showProfile()}
                    className={styles["menu-link-text"]}
                  >
                    My Travel Profile
                  </p>

                  {/* <p className={styles["padding"]}></p> */}
                  <p>
                    <a
                      href="https://yousmartthing.com/uk"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles["menu-link-text"]}
                    >
                      About
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://yousmartthing.atlassian.net/servicedesk/customer/portals"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles["menu-link-text"]}
                    >
                      Help
                    </a>
                  </p>
                </ul>
              </div>
            ) : (
              <div id="menu-open">
                <ul>
                  <p
                    onClick={() => this.login()}
                    className={styles["menu-link-text"]}
                  >
                    Sign in / Register
                  </p>

                  {/* <p className={styles["padding"]}></p> */}
                  <p>
                    <a
                      href="https://yousmartthing.com/uk"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles["menu-link-text"]}
                    >
                      About
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://yousmartthing.atlassian.net/servicedesk/customer/portals"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles["menu-link-text"]}
                    >
                      Help
                    </a>
                  </p>
                </ul>
              </div>
            )
          ) : (
            <div id="menu-authenticated">
              <ul>
                {/* <p
                  onClick={() => this.logout()}
                  className={styles["menu-link-text"]}
                >
                  Sign out
                </p> */}

                <p
                  onClick={() => this.showProfile()}
                  className={styles["menu-link-text"]}
                >
                  My Travel Profile
                </p>

                {/* <p className={styles["padding"]}></p> */}
                <p>
                  <a
                    href="/uk"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles["menu-link-text"]}
                  >
                    About
                  </a>
                </p>
                <p>
                  <a
                    href="https://yousmartthing.atlassian.net/servicedesk/customer/portals"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles["menu-link-text"]}
                  >
                    Help
                  </a>
                </p>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Profile;
