import ClusteringLocationOn from "../common/ClusteringLocationOn";
import { useContext, useEffect } from "react";
import ClusterContext from "./ClusterContext";
import { Marker } from "@urbica/react-map-gl";

//Handles displaying the primary markerGroup, using passed in events
const PrimaryMarkerGroup = ({
  isMultiDestination,
  events,
  singleNonMDEvent,
  setActiveEventSelectedOnMap,
  clusteringEnabled,
}) => {
  const { registerMarkers, unRegisterMarkers } = useContext(ClusterContext);

  useEffect(() => {
    if (isMultiDestination && clusteringEnabled) {
      var filteredMarkers = events
        .filter(
          (event) =>
            !event.hideLocationMarker && event?.accessPoints?.length > 0
        )
        .map((event) => (
          <Marker
            key={event.id}
            latitude={event.accessPoints[0].location.latitude}
            longitude={event.accessPoints[0].location.longitude}
            offsetLeft={-20}
            offsetTop={-40}
          >
            <ClusteringLocationOn
              event={event}
              onClick={() => setActiveEventSelectedOnMap(event)}
            />
          </Marker>
        ));
      registerMarkers(filteredMarkers);
      return () => {
        //Unregister the markers on unmount
        unRegisterMarkers(filteredMarkers);
      };
    }
  }, [
    registerMarkers,
    unRegisterMarkers,
    events,
    isMultiDestination,
    clusteringEnabled,
    setActiveEventSelectedOnMap,
  ]);

  return (
    <>
      {singleNonMDEvent && !isMultiDestination && (
        <Marker
          latitude={singleNonMDEvent.accessPoints[0].location.latitude}
          longitude={singleNonMDEvent.accessPoints[0].location.longitude}
          offsetLeft={-20}
          offsetTop={-40}
          clusterMe={false}
        >
          <ClusteringLocationOn
            event={singleNonMDEvent}
            onClick={() => setActiveEventSelectedOnMap(singleNonMDEvent)}
          ></ClusteringLocationOn>
        </Marker>
      )}
      {isMultiDestination &&
        !clusteringEnabled &&
        events
          .filter(
            (event) =>
              !event.hideLocationMarker && event?.accessPoints?.length > 0
          )
          .map((event, index) => (
            <Marker
              key={index}
              latitude={event.accessPoints[0].location.latitude}
              longitude={event.accessPoints[0].location.longitude}
              offsetLeft={-20}
              offsetTop={-40}
            >
              <ClusteringLocationOn
                event={event}
                onClick={() => setActiveEventSelectedOnMap(event)}
              />
            </Marker>
          ))}
    </>
  );
};

export default PrimaryMarkerGroup;
