import React, { Component } from "react";
import styles from "./RedirectionPopup.module.scss";
class RedirectionPopup extends Component {
  handlePopupClose = () => {
    this.props.handlePopupClose();
  };

  render() {
    const title = this.props.popUpContent.description;
    const linkText = this.props.popUpContent.message;

    return (
      <div className={styles["modal"]}>
        <div className={styles["modal-content"]}>
          <span className={styles["close"]} onClick={this.handlePopupClose}>
            &times;
          </span>

          <section>
            <h2 tabIndex="-1" className={styles["h2"]}>
              <div className={styles["header-content"]}>
                <div className={styles["header"]}>{title}</div>
              </div>
            </h2>
          </section>

          <div className={styles["pre-outer-container"]}>
            <div className={styles["pre-mobile-container"]}>
              <div className={styles["pre-inner-container"]}>
                <pre className={styles["pre"]}>
                  <a
                    href={this.props.redirectionURL}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles["a"]}
                  >
                    {linkText}
                  </a>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RedirectionPopup;
