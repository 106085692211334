import React, { Component } from "react";
import styles from "./Journey.module.scss";
import LegCollection from "../LegCollection";
import DOMPurify from "dompurify";
import { ReactComponent as Walk } from "../assets/round-directions_walk-24px.svg";
import { ReactComponent as Ferry } from "../assets/round-directions_boat-24px.svg";
import { ReactComponent as Rail } from "../assets/round-directions_railway-24px.svg";
import { ReactComponent as Bus } from "../assets/round-directions_bus-24px.svg";
import { ReactComponent as Tram } from "../assets/round-tram-24px.svg";
import { ReactComponent as Underground } from "../assets/round-directions_subway-24px.svg";
import { ReactComponent as ChevronRight } from "../assets/round-chevron_right-24px.svg";
import { ReactComponent as Drive } from "../assets/round-directions_car-24px.svg";
import { ReactComponent as Park } from "../assets/round-local_parking-24px.svg";
import { ReactComponent as Cycle } from "../assets/Bicycle.svg";
import { ReactComponent as CycleDock } from "../assets/cycle_hire.svg";
import { ReactComponent as Destination } from "../assets/round-location_filled-24px.svg";
import { ReactComponent as Plane } from "../assets/plane-solid.svg";
import { ReactComponent as EvStation } from "../assets/round-ev_station-24px.svg";
import { ReactComponent as Alert } from "../assets/disruption.svg";
import { ReactComponent as CarbonInfo } from "../assets/carbon-info.svg";
import PlayIcon from "../../components/assets/play-button.png";
import PauseIcon from "../../components/assets/pause-button.png";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../Context/ThemeContext";
import AdditionalServiceButton from "../../components/AdditionalService/AdditionalServiceButton";
import { ReactComponent as Taxi } from "../assets/additional_service_taxi.svg";
import { ReactComponent as Carbon } from "../assets/carbon-world.svg";

class Journey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrivalTime: new Date(),
      departureTime: new Date(),
      itinerary: [],
      animateLegs: false,
      isButtonHovered: false,
    };
  }

  setAnimateLegs = (shouldAnimateLegs) => {
    this.setState({ animateLegs: shouldAnimateLegs });
  };

  toggleSelection = () => {
    this.props.toggleSelection();
  };

  toggleRequestAssistance = (travelForecastId) => {
    this.props.toggleRequestAssistance(travelForecastId);
  };

  handleLegUpdateClicked = (updatedLegs) => {
    this.props.onLegUpdateClicked(updatedLegs);
  };

  handleThirdPartyAction = (customData) => {
    this.props.onThirdPartyActionClicked(customData);
  };

  toggleHighlight = () => {
    this.props.setAlertsButton(false);
    if (this.props.journey.legs.length > 0) {
      this.props.toggleHighlightedJourney(this.props.journey.order);
      this.props.onJourneyClicked(this.props.journey.legs);
    }
  };

  toggleMoreOptions = () => {
    if (this.props.subJourneysExpanded) {
      this.props.toggleActiveJourney(null);
    }
    this.props.setJourneysExpanded(
      this.props.journey.id,
      !this.props.subJourneysExpanded
    );
  };

  toggleClass = () => {
    this.props.journey.active = !this.props.journey.active;
    if (this.props.journey.active === false) {
      this.props.toggleActiveJourney(null);
    } else {
      this.props.toggleActiveJourney(this.props.journey.order);
    }
  };

  componentDidMount = () => {
    var itinerary = [];

    const legsCount = this.props.journey.legs.length - 1;
    if (this.props.journey.order === this.props.activeJourney) {
      this.props.toggleHighlightedJourney(this.props.journey.order);
    }

    var disruptionMessages =
      this.props.journey.legs?.flatMap((leg) => leg.disruptionMessages) ?? [];

    if (disruptionMessages && disruptionMessages.length > 0) {
      itinerary.push(
        <span>
          <Alert
            className={styles["Alert"]}
            onClick={() => {
              this.props.toggleDisruptionsModal(
                !this.props.displayDisruptionsModal,
                disruptionMessages
              );
            }}
          />
        </span>
      );
    }

    // Irrespective of the legs cycle mode, if it is in a "cycledock" journey, show that icon.
    const formattedLegs = this.props.journey.legs.map((leg) => {
      if (
        this.props.journey.mode === "cycledock" &&
        leg.mode.toLowerCase().includes("cycle")
      ) {
        leg.mode = "CycleDock";
      }
      return leg;
    });

    formattedLegs.forEach((leg, index) => {
      if (legsCount !== index) {
        itinerary.push(
          {
            Walk: (
              <span key={index}>
                <Walk className={styles["Walk"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Rail: (
              <span key={index}>
                <Rail className={styles["Rail"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Bus: (
              <span key={index}>
                <Bus className={styles["Bus"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Ferry: (
              <span key={index}>
                <Ferry className={styles["Ferry"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Tram: (
              <span key={index}>
                <Tram className={styles["Tram"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Coach: (
              <span key={index}>
                <Bus className={styles["Coach"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Metro: (
              <span key={index}>
                <Tram className={styles["Metro"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Underground: (
              <span key={index}>
                <Underground className={styles["Underground"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Drive: (
              <span key={index}>
                <Drive className={styles["Drive"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            // use Drive icon for now
            ElectricVehicle: (
              <span key={index}>
                <Drive className={styles["Drive"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Park: (
              <span key={index}>
                <Park className={styles["Park"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            EvStation: (
              <span key={index}>
                <EvStation className={styles["EvStation"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            CycleDock: (
              <span key={index}>
                <CycleDock className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Cycle: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            CycleStreetsQuietest: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            CycleStreetsFastest: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            CycleStreetsBalanced: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            CycleStreetsDefault: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Destination: (
              <span key={index}>
                <Destination className={styles["Destination"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Flight: (
              <span>
                <Plane className={styles["Plane"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
          }[leg.mode]
        );
      } else {
        itinerary.push(
          {
            Walk: (
              <span key={index}>
                <Walk
                  key={index}
                  className={styles["Walk"]}
                />
              </span>
            ),
            Rail: (
              <span key={index}>
                <Rail
                  key={index}
                  className={styles["Rail"]}
                />
              </span>
            ),
            Bus: (
              <span key={index}>
                <Bus
                  key={index}
                  className={styles["Bus"]}
                />
              </span>
            ),
            Ferry: (
              <span key={index}>
                <Ferry
                  key={index}
                  className={styles["Ferry"]}
                />
              </span>
            ),
            Tram: (
              <span key={index}>
                <Tram
                  key={index}
                  className={styles["Tram"]}
                />
              </span>
            ),
            Coach: (
              <span key={index}>
                <Bus
                  key={index}
                  className={styles["Coach"]}
                />
              </span>
            ),
            Metro: (
              <span key={index}>
                <Tram
                  key={index}
                  className={styles["Metro"]}
                />
              </span>
            ),
            Underground: (
              <span key={index}>
                <Underground
                  key={index}
                  className={styles["Underground"]}
                />
              </span>
            ),
            Drive: (
              <span key={index}>
                <Drive
                  key={index}
                  className={styles["Drive"]}
                />
              </span>
            ),
            ElectricVehicle: (
              <span key={index}>
                <Drive
                  key={index}
                  className={styles["Drive"]}
                />
              </span>
            ),
            Park: (
              <span key={index}>
                <Park
                  key={index}
                  className={styles["Park"]}
                />
              </span>
            ),
            EvStation: (
              <span key={index}>
                <EvStation
                  key={index}
                  className={styles["EvStation"]}
                />
              </span>
            ),
            CycleDock: (
              <span key={index}>
                <CycleDock className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Cycle: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
              </span>
            ),
            CycleStreetsQuietest: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
              </span>
            ),
            CycleStreetsFastest: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
              </span>
            ),
            CycleStreetsBalanced: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
              </span>
            ),
            CycleStreetsDefault: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
              </span>
            ),
            Destination: (
              <span key={index}>
                <Destination className={styles["Destination"]} />
              </span>
            ),
            Flight: (
              <span>
                <Plane className={styles["Plane"]} />
              </span>
            ),
          }[leg.mode]
        );
      }
    });

    this.setState({
      arrivalTime: new Date(
        this.props.journey.legs[this.props.journey.legs.length - 1].arrivalTime
      ),
      departureTime: new Date(this.props.journey.legs[0].departureTime),
      itinerary: itinerary,
    });
  };

  round = (num, places) => {
    return +(Math.round(num + "e+" + places) + "e-" + places);
  };

  handleCarbonClicked = (carbonUrl) => {
    if (!carbonUrl) return;
    window.open(carbonUrl, "_blank");
  };

  renderKCalories = (kCalories) => {
    const roundTo = 0;

    if (!kCalories || this.round(kCalories, roundTo) === 0) {
      return "";
    }

    return this.round(kCalories, roundTo) + " cal";
  };

  renderTime = (journeyDuration) => {
    var timeStr = "";

    if (Math.floor(journeyDuration / 60) === 0) {
      timeStr += "";
    } else {
      timeStr += Math.floor(journeyDuration / 60) + " h";
    }

    if (journeyDuration % 60 === 0) {
      timeStr += "";
    } else {
      timeStr += " " + (journeyDuration % 60) + " min";
    }

    return timeStr;
  };

  getServiceUrl = (service, startLocation, endLocation, pickupDate) => {
    var url = "";
    if (service.serviceProviderCode == "100") {
      const pickupDetails = {
        latitude: startLocation.latitude,
        longitude: startLocation.longitude,
      };

      const dropOffDetails = {
        latitude: endLocation.latitude,
        longitude: endLocation.longitude,
      };

      const formattedTime = pickupDate.toISOString().replace(/\:/g, "%3A");
      url =
        service.baseUrl +
        `&pickup=${encodeURIComponent(
          JSON.stringify(pickupDetails)
        )}&drop%5B0%5D=${encodeURIComponent(
          JSON.stringify(dropOffDetails)
        )}&pickup_formatted_time=${formattedTime}&utm_campaign=yst`;
      return url;
    }
  };

  getServiceIcon = (category) => {
    switch (category) {
      case "TAXI":
        return <Taxi />;
      default:
        return null;
    }
  };

  render() {
    const { itinerary, departureTime } = this.state;

    const {
      legs,
      journeyDuration,
      startLocation,
      endLocation,
      fares,
      active,
      show,
      description,
      message,
      highlight,
      thirdPartySource,
      thirdPartySourceManipulation,
      carbonFootprint,
      kCalories,
      mode,
      additionalServices,
      formattedCarbonValue,
      carbonUrl,
      carbonButtonMessage,
    } = this.props.journey;

    let thirdPartyActionData = {
      message: message,
      sourceURL: thirdPartySource,
      uRLManipulation: thirdPartySourceManipulation,
      includeEmail: false,
      description: description,
    };

    //TODO: Encapsulate within DumfriesCouncilAuthProvider method.
    const thirdPartyData = this.props.thirdPartyData
      ? JSON.parse(this.props.thirdPartyData)
      : null;
    const thirdPartyAlternative = this.props.thirdPartyAlternative
      ? JSON.parse(this.props.thirdPartyAlternative)
      : null;

    // Assign alternative information for not logged in users
    if (thirdPartyAlternative && thirdPartyAlternative.message) {
      thirdPartyActionData.message = thirdPartyAlternative.message;
      thirdPartyActionData.sourceURL = thirdPartyAlternative.sourceURL;
      thirdPartyActionData.uRLManipulation =
        thirdPartyAlternative.uRLManipulation;
      thirdPartyActionData.includeEmail = thirdPartyAlternative.includeEmail;
    }

    const showCarbonButton =
      highlight && carbonFootprint && this.round(carbonFootprint, 1) != 0;

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <li
            onClick={this.toggleHighlight}
            className={`${styles["Journey"]} ${
              show ? styles["show"] : styles["hide"]
            } ${highlight ? styles["highlight"] : styles["fade"]}`}
            style={{ "--theme-color": theme.theme.primaryColour }}
          >
            <div
              role="button"
              tabIndex="0"
              aria-pressed="false"
              className={styles["journey-sub"]}
              style={{
                borderColor: "blue",
              }}
            >
              <div className={styles["journey-title-outer-container-full"]}>
                <div className={styles["journey-title-inner-container"]}>
                  <div className={styles["journey-title"]}>
                    <div className={styles["split-journey-title"]}>
                      <span>{description}</span>
                      {!theme?.hideClientElements?.includes("hideCO2e") ? (
                        <>
                          <span
                            style={{
                              color: theme.theme.primaryColour,
                            }}
                            className={
                              styles["journey-detail-carbon-unselected"]
                            }
                            onClick={() => {
                              if (!showCarbonButton) return;
                              if (!theme?.carbonRemovalValues?.showCarbonButton)
                                return;
                              this.handleCarbonClicked(carbonUrl);
                            }}
                          >
                            {formattedCarbonValue}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div
                        className={styles["split-journey-subtitle-container"]}
                      >
                        <span className={styles["split-journey-subtitle"]}>
                          {itinerary}
                        </span>
                        {!theme?.hideClientElements?.includes(
                          "hideCalorieCount"
                        ) ? (
                          <span className={styles["journey-detail-kcalories"]}>
                            {this.renderKCalories(kCalories)}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {!thirdPartySource && (
                      <div className={styles["journey-subtitle-container"]}>
                        <span className={styles["journey-subtitle"]}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(message, {
                                ADD_ATTR: ["target"],
                                // sanitize: true, TODO: Re-implement this!
                              }),
                            }}
                          />
                        </span>
                      </div>
                    )}
                    {thirdPartySource && (
                      <div
                        className={styles["journey-subtitle-container"]}
                        onClick={
                          highlight === true &&
                          thirdPartyActionData.uRLManipulation
                            ? () =>
                                this.handleThirdPartyAction(
                                  thirdPartyActionData
                                )
                            : this.toggleHighlight
                        }
                      >
                        {thirdPartyActionData.uRLManipulation ? (
                          <span
                            className={styles["journey-subtitle-thirdparty"]}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  thirdPartyActionData.message,
                                  {
                                    ADD_ATTR: ["target"],
                                    // sanitize: true, TODO: Re-implement this!
                                  }
                                ),
                              }}
                            />
                          </span>
                        ) : (
                          <span className={styles["journey-subtitle"]}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  thirdPartyActionData.message,
                                  {
                                    ADD_ATTR: ["target"],
                                    // sanitize: true, TODO: Re-implement this!
                                  }
                                ),
                              }}
                            />
                          </span>
                        )}
                      </div>
                    )}
                    <div>
                      <div
                        className={`${
                          styles["split-journey-subtitle-container"]
                        } ${
                          this.props.journey.legs.length > 1
                            ? styles["with-button"]
                            : ""
                        }`}
                      >
                        <span
                          data-cy={"parentLeg"}
                          className={styles["journey-subtitle-a"]}
                        >
                          {this.props.journey.legs.length > 1 && active ? (
                            <button
                              className={styles["play-pause-button"]}
                              style={{
                                backgroundColor: !this.state.isButtonHovered
                                  ? theme.theme.primaryColour
                                  : theme.theme.hoverColour,
                              }}
                              onMouseEnter={() => {
                                this.setState({ isButtonHovered: true });
                              }}
                              onMouseLeave={() => {
                                this.setState({ isButtonHovered: false });
                              }}
                              onClick={() => {
                                if (!active) {
                                  this.toggleClass();
                                }
                                this.setAnimateLegs(!this.state.animateLegs);
                                this.props.setTerminateLegAnimation(false);
                              }}
                            >
                              {!this.state.animateLegs ? (
                                <img
                                  src={PlayIcon}
                                  alt="Play"
                                  className={styles["play-pause-icon"]}
                                />
                              ) : (
                                <img
                                  src={PauseIcon}
                                  alt="Pause"
                                  className={styles["play-pause-icon"]}
                                />
                              )}
                            </button>
                          ) : (
                            ""
                          )}
                          {this.props.journey.legs.length > 0 && (
                            <a style={{ color: theme.theme.primaryColour }}>
                              <button
                                tabIndex="0"
                                className={styles["a"]}
                                style={{ color: theme.theme.primaryColour }}
                                onClick={() => {
                                  this.toggleClass();
                                  this.setAnimateLegs(false);
                                }}
                                aria-label={
                                  active
                                    ? "Closes the listbox, which shows journey legs"
                                    : "Opens the listbox, which shows journey legs"
                                }
                              >
                                {active ? (
                                  <FormattedMessage id="hideDetails" />
                                ) : (
                                  <FormattedMessage id="showDetails" />
                                )}
                              </button>
                            </a>
                          )}
                          {this.props.journey.isParentJourney &&
                            this.props.journey.active === false && (
                              <a style={{ color: theme.theme.primaryColour }}>
                                &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                                <button
                                  tabIndex="0"
                                  className={styles["a"]}
                                  style={{ color: theme.theme.primaryColour }}
                                  onClick={this.toggleMoreOptions}
                                  aria-label={
                                    this.props.subJourneysExpanded
                                      ? "Closes the listbox, which shows alternative journeys"
                                      : "Opens the listbox, which shows alternative journeys"
                                  }
                                >
                                  {this.props.subJourneysExpanded ? (
                                    <FormattedMessage id="fewerOptions" />
                                  ) : (
                                    <FormattedMessage id="moreOptions" />
                                  )}
                                </button>
                              </a>
                            )}
                        </span>
                        <span className={styles["journey-detail-time"]}>
                          {this.renderTime(journeyDuration)}
                        </span>
                      </div>
                    </div>
                    {highlight &&
                    showCarbonButton &&
                    theme?.carbonRemovalValues?.showCarbonButton ? (
                      <div
                        style={{
                          display: "inline-flex",
                          width: "101.5%",
                          marginTop: "4px",
                        }}
                      >
                        <AdditionalServiceButton
                          key={0}
                          icon={<Carbon />}
                          text={carbonButtonMessage}
                          onClick={() => {
                            if (!showCarbonButton) return;
                            this.handleCarbonClicked(carbonUrl);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {highlight && additionalServices != null && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "101.5%",
                          marginTop:
                            highlight &&
                            showCarbonButton &&
                            theme?.carbonRemovalValues?.url
                              ? "0px"
                              : "24px",
                        }}
                      >
                        {additionalServices.map((service, index) => (
                          <AdditionalServiceButton
                            key={index + 1}
                            icon={this.getServiceIcon(service.category)}
                            text={`${service.labelText}`}
                            link={() => {
                              const firstDriveLeg =
                                this.props.journey.legs.find(
                                  (leg) => leg.mode === "Drive"
                                );

                              const serviceStartLocation =
                                this.props.journey.mode === "drivetraindrive"
                                  ? firstDriveLeg?.startLocation
                                  : startLocation;

                              const serviceEndLocation =
                                this.props.journey.mode === "drivetraindrive"
                                  ? firstDriveLeg?.endLocation
                                  : endLocation;

                              return this.getServiceUrl(
                                service,
                                serviceStartLocation,
                                serviceEndLocation,
                                departureTime
                              );
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <LegCollection
              active={active}
              legs={legs}
              fares={fares}
              animateLegs={this.state.animateLegs}
              setAnimateLegs={this.setAnimateLegs}
              onJourneyLegClicked={this.props.onJourneyLegClicked}
              onLegUpdateClicked={this.handleLegUpdateClicked}
              enableNavigation={this.props.enableNavigation}
              apiToken={this.props.apiToken}
              toggleSelection={this.toggleSelection}
              showTravelPlan={this.props.showTravelPlan}
              travelForecast={this.props.travelForecast}
              toggleRequestAssistance={this.toggleRequestAssistance}
              auth={this.props.auth}
              showInfoPopup={this.props.showInfoPopup}
              setInitialViewPort={this.props.setInitialViewPort}
              terminateLegAnimation={this.props.terminateLegAnimation}
              setTerminateLegAnimation={this.props.setTerminateLegAnimation}
              toggleDirectionsModal={this.props.toggleDirectionsModal}
              setSelectedLegIndex={this.props.setSelectedLegIndex}
              allowEditTime={mode !== "publictransport"}
            />
          </li>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Journey;
