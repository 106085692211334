import localData from "./localDataService";
import authLocalData from "./authLocalDataService";

const getUserOrganisation = () =>
  localData.getItem("userOrganisation", JSON.parse);
const setUserOrganisation = (value: any) =>
  localData.setItem("userOrganisation", value);

const getUserOrganisationID = () => {
  const userOrganisation = getUserOrganisation();
  return userOrganisation ? userOrganisation.id : null;
};

const clear = () => {
  setUserOrganisation(null);
};

const userLocalDataService = {
  clear,

  getUserRole: authLocalData.getUserRole,
  getAPIMUser: authLocalData.getAPIMUser,
  getUserOrganisation,
  getUserOrganisationID,

  setUserRole: authLocalData.setUserRole,
  setUserOrganisation,
};

export default userLocalDataService;
