/*  LogService.
A wrapper for console.log(), etc, in case we decide to implement
a service such as Sentry in the future alongside console logging.
See: https://docs.sentry.io/platforms/javascript/guides/react/
*/

const init = () => {};

const info = (message?: any, ...optionalParams: any[]): void => {
  console.log(message, optionalParams);
};

const warn = (message?: any, ...optionalParams: any[]): void => {
  console.warn(message, optionalParams);
};

const error = (message?: any, ...optionalParams: any[]): void => {
  console.error(message, optionalParams);
};

const logService = {
  init,
  info,
  warn,
  error,
};

export default logService;
