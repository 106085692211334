import { Marker } from "@urbica/react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./ClusterTrafficMarker.module.scss";
import React from "react";
class ClusterTrafficMarker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onClick, ...cluster } = this.props;
    onClick(cluster);
  }

  render() {
    const { longitude, latitude, pointCount } = this.props;

    return (
      <div>
        {pointCount >= 100 ? (
          <div>
            {pointCount >= 1000 ? (
              <Marker
                onClick={this.onClick}
                longitude={longitude}
                latitude={latitude}
                offsetLeft={-20}
                offsetTop={-40}
              >
                <div className={styles["clustering-marker-style-fourdigits"]}>
                  {pointCount}
                </div>
              </Marker>
            ) : (
              <Marker
                onClick={this.onClick}
                longitude={longitude}
                latitude={latitude}
                offsetLeft={-20}
                offsetTop={-40}
              >
                <div className={styles["clustering-marker-style-threedigits"]}>
                  {pointCount}
                </div>
              </Marker>
            )}
          </div>
        ) : (
          <div>
            {pointCount >= 10 ? (
              <Marker
                onClick={this.onClick}
                longitude={longitude}
                latitude={latitude}
                offsetLeft={-20}
                offsetTop={-40}
              >
                <div className={styles["clustering-marker-style-twodigits"]}>
                  {pointCount}
                </div>
              </Marker>
            ) : (
              <Marker
                onClick={this.onClick}
                longitude={longitude}
                latitude={latitude}
                offsetLeft={-20}
                offsetTop={-40}
              >
                <div className={styles["clustering-marker-style-onedigit"]}>
                  {pointCount}
                </div>
              </Marker>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ClusterTrafficMarker;
