import localData from "./localDataService";

const setAuth = (value: object | null) => localData.setItem("auth", value);
const getAuth = () => localData.getItem("auth", JSON.parse);

const getAccessToken = (): string | null =>  getAuth()?.accessToken;

const getExpiresAt = (): number => getAuth()?.expiresAt;

const getIdToken = (): string => getAuth()?.idToken;

const getAPIMUser = () => localData.getItem("user", JSON.parse);
const setAPIMUser = (value: any | null) => localData.setItem("user", value);

const getUserRole = (): string => localData.getItem("userRole", null);
const setUserRole = (value: string | null) =>
  localData.setItem("userRole", value);

const clear = () => {
  setAuth(null)
  setAPIMUser(null);
  setUserRole(null);
};

const authLocalDataService = {
  clear,

  getAccessToken,
  getExpiresAt,
  getIdToken,
  getAPIMUser,
  getUserRole,

  setAuth,
  setAPIMUser,
  setUserRole,
};

export default authLocalDataService;
