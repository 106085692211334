export const MAPTILER_PATH_WITH_TOKEN =
  "https://api.maptiler.com/maps/dc601848-95ab-408c-8c80-0f00f3631643/style.json?key=qcwcn89fqL6UsgaS7vSW";

export const MAPBOX_PATH =
  "mapbox://styles/yousmartthingadmin/ck6gd18uf0mg41ip62jm7bg5c";

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoieW91c21hcnR0aGluZ2FkbWluIiwiYSI6ImNqeHI2eWRlcjA1Y3ozbW1mdzFvczR0ZGgifQ.opR6hJLEwjylFbLBZXCdfw";

export const MOBILE_JOURNEY_MAP_HEIGHT = 300;

export const MOBILE_VIEW_THRESHOLD = 600;

export const DESKTOP_JOURNEY_MAP_WIDTH_GAP = 441;

export const MOBILE_MAP_HEIGHT_GAP = 165;
