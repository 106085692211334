import { Component } from "react";
import styles from "./NominatedAssistance.module.scss";
import { ReactComponent as Email } from "../../assets/round-email-24px.svg";
import { ReactComponent as Phone } from "../../assets/round-phone_iphone-24px.svg";
import { ReactComponent as Person } from "../../assets/person_black_24dp.svg";
import { ReactComponent as Check } from "../../assets/round-check-24px.svg";
import axios from "axios";
import userLocalDataService from "./../../../services/data/userLocalDataService";

class NominatedAssistance extends Component {
  constructor(props) {
    super(props);
    const apimUser = userLocalDataService.getAPIMUser();
    this.state = {
      usr: apimUser.firstName,
      assistanceSettings: {
        isCurrentLocationShared: false,
        isTravelPlanShared: false,
        contacts: [
          {
            email: null,
            name: null,
            phone: null,
            status: null,
          },
        ],
      },
      submitted: false,
      showPending: false,
    };
  }

  componentDidMount = () => {
    if (this.props.assistanceSettings !== null) {
      //force currentLocation and travelPlan shared to be true on start
      var as = this.props.assistanceSettings;
      as.isCurrentLocationShared = true;
      as.isTravelPlanShared = true;
      this.setState({ assistanceSettings: this.props.assistanceSettings });
      if (this.state.assistanceSettings.contacts.length !== 0) {
        if (this.props.assistanceSettings.contacts[0].status === "Pending") {
          this.setState({ showPending: true });
        }
      }
    }

    if (this.props.profileId === null) {
      this.props.createUserProfile();
    }

    console.log(this.state.showPending);
  };

  handleNameChange = (event) => {
    var assistanceSettings = this.state.assistanceSettings;
    assistanceSettings.contacts[0].name = event.target.value;
    this.setState({
      assistanceSettings: assistanceSettings,
    });
  };

  handlePhoneChange = (event) => {
    this.setState({ showPending: false });
    var assistanceSettings = this.state.assistanceSettings;
    assistanceSettings.contacts[0].phone = event.target.value;
    this.setState({
      assistanceSettings: assistanceSettings,
    });
  };

  handleEmailChange = (event) => {
    this.setState({ showPending: false });
    var assistanceSettings = this.state.assistanceSettings;
    assistanceSettings.contacts[0].email = event.target.value;
    this.setState({
      assistanceSettings: assistanceSettings,
    });
  };

  handleLetTravelPlanChange = () => {
    let assistanceSettings = { ...this.state.assistanceSettings };
    assistanceSettings.isTravelPlanShared =
      !this.state.assistanceSettings.isTravelPlanShared;
    this.setState({
      assistanceSettings: assistanceSettings,
    });
  };

  handleLetLocationChange = () => {
    let assistanceSettings = { ...this.state.assistanceSettings };
    assistanceSettings.isCurrentLocationShared =
      !this.state.assistanceSettings.isCurrentLocationShared;
    this.setState({
      assistanceSettings: assistanceSettings,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true, showPending: true }, function () {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            "profile/" +
            this.props.profileId,
          {
            headers: {
              Authorization: this.props.apiToken,
            },
          }
        )
        .then((response) => {
          const data = {
            ...response.data,
            assistanceSettings: {
              isCurrentLocationShared:
                this.state.assistanceSettings.isCurrentLocationShared,
              isTravelPlanShared:
                this.state.assistanceSettings.isTravelPlanShared,
              contacts: [
                {
                  email: this.state.assistanceSettings.contacts[0].email,
                  name: this.state.assistanceSettings.contacts[0].name,
                  phone: this.state.assistanceSettings.contacts[0].phone,
                  status: "Pending",
                },
              ],
            },
          };

          return axios
            .put(
              process.env.REACT_APP_API_BASE_URL +
                "profile/" +
                this.props.profileId,
              data,
              {
                headers: {
                  Authorization: this.props.apiToken,
                },
              }
            )
            .then((response) => {
              //this.props.toggleNominatePage();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  render() {
    const { assistanceSettings, submitted, showPending } = this.state;
    return (
      <div>
        <div
          id="edit_header"
          className={styles["edit-profile-text-span"]}
        ></div>
        <div className={styles["modal-subtitle"]}>
          <p>
            <b>Add your nominated assistance contact</b>
          </p>
        </div>
        <div className={styles["NominatedAssistance"]}>
          <section>
            <form
              action="#"
              onSubmit={this.handleSubmit}
              id="nominatedassistance"
            >
              <div>
                <div className={styles["form-item"]}>
                  <div className="inspectletIgnore">
                    <div className={styles["form-item-content-container"]}>
                      <label
                        className={styles["form-item-label"]}
                        htmlFor="tel"
                      >
                        Name *
                      </label>
                      <div dir="ltr">
                        <div className={styles["form-item-control"]}>
                          <div className={styles["form-icon-outer-sub"]}>
                            <div className={styles["form-icon-inner-sub"]}>
                              <div
                                className={styles["form-icon-outer-container"]}
                              >
                                <div
                                  className={
                                    styles["form-icon-inner-container"]
                                  }
                                >
                                  <Person className={styles["Person"]} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles["form-input-container"]}>
                            {assistanceSettings.contacts[0].name !== null ? (
                              <input
                                onChange={this.handleNameChange}
                                className={styles["form-input"]}
                                type="text"
                                id="nomineename"
                                name="nomineename"
                                placeholder={
                                  assistanceSettings.contacts[0].name
                                }
                                value={assistanceSettings.contacts[0].name}
                              />
                            ) : (
                              <input
                                onChange={this.handleNameChange}
                                className={styles["form-input"]}
                                type="text"
                                id="nomineename"
                                name="nomineename"
                                placeholder="Name *"
                                value={assistanceSettings.contacts[0].name}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles["form-item"]}>
                  <div className="inspectletIgnore">
                    <div className={styles["form-item-content-container"]}>
                      <label
                        className={styles["form-item-label"]}
                        htmlFor="tel"
                      >
                        Contact Number
                      </label>
                      <div dir="ltr">
                        <div className={styles["form-item-control"]}>
                          <div className={styles["form-icon-outer-sub"]}>
                            <div className={styles["form-icon-inner-sub"]}>
                              <div
                                className={styles["form-icon-outer-container"]}
                              >
                                <div
                                  className={
                                    styles["form-icon-inner-container"]
                                  }
                                >
                                  <Phone className={styles["Phone"]} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles["form-input-container"]}>
                            {assistanceSettings.contacts[0].phone !== null ? (
                              <input
                                onChange={this.handlePhoneChange}
                                className={styles["form-input"]}
                                type="tel"
                                id="mobilePhoneNumber"
                                name="mobilePhoneNumber"
                                placeholder={
                                  assistanceSettings.contacts[0].phone
                                }
                                value={assistanceSettings.contacts[0].phone}
                              />
                            ) : (
                              <input
                                onChange={this.handlePhoneChange}
                                className={styles["form-input"]}
                                type="tel"
                                id="mobilePhoneNumber"
                                name="mobilePhoneNumber"
                                placeholder="Contact Number"
                                value={assistanceSettings.contacts[0].phone}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles["form-item"]}>
                  <div className="inspectletIgnore">
                    <div className={styles["form-item-content-container"]}>
                      <label
                        className={styles["form-item-label"]}
                        htmlFor="email"
                      >
                        Email address *
                      </label>
                      <div dir="ltr">
                        <div className={styles["form-item-control"]}>
                          <div className={styles["form-icon-outer-sub"]}>
                            <div className={styles["form-icon-inner-sub"]}>
                              <div
                                className={styles["form-icon-outer-container"]}
                              >
                                <div
                                  className={
                                    styles["form-icon-inner-container"]
                                  }
                                >
                                  <Email className={styles["Email"]} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles["form-input-container"]}>
                            {assistanceSettings.contacts[0].email !== null ? (
                              <input
                                onChange={this.handleEmailChange}
                                className={styles["form-input"]}
                                type="email"
                                id="emailAddress"
                                name="emailAddress"
                                placeholder={
                                  assistanceSettings.contacts[0].email
                                }
                                value={assistanceSettings.contacts[0].email}
                              />
                            ) : (
                              <input
                                onChange={this.handleEmailChange}
                                className={styles["form-input"]}
                                type="email"
                                id="emailAddress"
                                name="emailAddress"
                                placeholder="Email address *"
                                value={assistanceSettings.contacts[0].email}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {submitted &&
                    !assistanceSettings.contacts[0].phone &&
                    !assistanceSettings.contacts[0].email && (
                      <div className={styles["error"]}>
                        Email or Phone Number is required
                      </div>
                    )}
                </div>
                {submitted && (
                  <p>
                    Thank you for registering your nominated assistance contact.
                    We will update your profile when your request has been
                    confirmed.
                  </p>
                )}
                {showPending ? (
                  <div className={styles["confirm-button-container"]}>
                    <button
                      type="submit"
                      form="nominatedassistance"
                      className={styles["pending-button"]}
                      disabled={true}
                      aria-label="pending confirmation from assistance contact"
                    >
                      <span className={styles["pending-button-span"]}>
                        Confirmation pending
                      </span>
                    </button>
                  </div>
                ) : (
                  <div className={styles["confirm-button-container"]}>
                    <button
                      type="submit"
                      form="nominatedassistance"
                      className={styles["confirm-button"]}
                      aria-label="Add assistance contact"
                    >
                      <span className={styles["confirm-button-span"]}>
                        Add Contact
                      </span>
                    </button>
                  </div>
                )}
                <section>
                  <div className={styles["options-subtitle-container"]}>
                    <div className={styles["options-subtitle"]}>
                      Please share
                    </div>
                  </div>
                  <div className={styles["option-item"]}>
                    <div
                      className={styles["option-item-label-outer-container"]}
                    >
                      <div
                        className={styles["option-item-label-inner-container"]}
                      >
                        <label className={styles["option-item-label"]}>
                          <div
                            className={
                              styles["option-item-title-outer-container"]
                            }
                          >
                            <div
                              className={
                                styles["option-item-title-inner-container"]
                              }
                            >
                              <div className={styles["option-item-title"]}>
                                <span
                                  className={styles["option-item-title-span"]}
                                >
                                  My Travel Plan
                                </span>
                              </div>
                            </div>
                            <div
                              className={
                                styles["option-item-control-outer-container"]
                              }
                            >
                              <div
                                className={
                                  styles["option-item-control-inner-container"]
                                }
                              >
                                <span
                                  className={styles["option-item-control-span"]}
                                >
                                  <input
                                    checked={
                                      assistanceSettings.isTravelPlanShared
                                    }
                                    onChange={this.handleLetTravelPlanChange}
                                    type="checkbox"
                                    className={
                                      styles["option-item-control-input"]
                                    }
                                  />
                                  <span
                                    className={
                                      assistanceSettings.isTravelPlanShared
                                        ? styles[
                                            "option-item-control-icon-active"
                                          ]
                                        : styles[
                                            "option-item-control-icon-inactive"
                                          ]
                                    }
                                  >
                                    {assistanceSettings.isTravelPlanShared && (
                                      <span
                                        className={
                                          styles[
                                            "option-item-control-icon-inner-span"
                                          ]
                                        }
                                      >
                                        <Check className={styles["Check"]} />
                                      </span>
                                    )}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={styles["option-item"]}>
                    <div
                      className={styles["option-item-label-outer-container"]}
                    >
                      <div
                        className={styles["option-item-label-inner-container"]}
                      >
                        <label className={styles["option-item-label"]}>
                          <div
                            className={
                              styles["option-item-title-outer-container"]
                            }
                          >
                            <div
                              className={
                                styles["option-item-title-inner-container"]
                              }
                            >
                              <div className={styles["option-item-title"]}>
                                <span
                                  className={styles["option-item-title-span"]}
                                >
                                  My Current Location
                                </span>
                              </div>
                            </div>
                            <div
                              className={
                                styles["option-item-control-outer-container"]
                              }
                            >
                              <div
                                className={
                                  styles["option-item-control-inner-container"]
                                }
                              >
                                <span
                                  className={styles["option-item-control-span"]}
                                >
                                  <input
                                    checked={
                                      assistanceSettings.isCurrentLocationShared
                                    }
                                    onChange={this.handleLetLocationChange}
                                    type="checkbox"
                                    className={
                                      styles["option-item-control-input"]
                                    }
                                  />
                                  <span
                                    className={
                                      assistanceSettings.isCurrentLocationShared
                                        ? styles[
                                            "option-item-control-icon-active"
                                          ]
                                        : styles[
                                            "option-item-control-icon-inactive"
                                          ]
                                    }
                                  >
                                    {assistanceSettings.isCurrentLocationShared && (
                                      <span
                                        className={
                                          styles[
                                            "option-item-control-icon-inner-span"
                                          ]
                                        }
                                      >
                                        <Check className={styles["Check"]} />
                                      </span>
                                    )}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {submitted &&
                    !assistanceSettings.isTravelPlanShared &&
                    !assistanceSettings.isCurrentLocationShared && (
                      <div className={styles["error"]}>
                        You need to share your travel plan or location with your
                        assistance nominee
                      </div>
                    )}
                </section>
              </div>
            </form>
          </section>
        </div>
      </div>
    );
  }
}

export default NominatedAssistance;
