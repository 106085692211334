import React from "react";
import LoadingMessages from "../common/LoadingMessages/";
import { useIntl } from "react-intl";

function JourneyLoadingMessages() {
  //
  const intl = useIntl();
  const messages = [
    intl.formatMessage({ id: "comparingJourney" }),
    intl.formatMessage({ id: "workingRoutes" }),
    intl.formatMessage({ id: "checkingAlternatives" }),
  ];
  const messageDuration = 2000;
  const dotsCyclePerDuration = 2;

  return (
    <LoadingMessages
      messages={messages}
      messageDuration={messageDuration}
      dotsCyclePerDuration={dotsCyclePerDuration}
    />
  );
}

export default JourneyLoadingMessages;
