import React, { Component } from "react";
import styles from "./Modal.module.scss";
import { ThemeContext } from "../../../Context/ThemeContext";

import { ReactComponent as Close } from "../../assets/round-close-24px.svg";
class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdateButtonHovered: false,
    };
  }

  close = () => {
    this.props.close();
  };

  finish = () => {
    this.props.finish();
  };

  render() {
    const { children } = this.props;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={styles["Modal"]}>
            <div
              className={styles["content"]}
              style={{
                overflowY: this.props.shouldOverflow ? "scroll" : "hidden",
              }}
              role="dialog"
            >
              <div className={styles["header"]}>
                <div className={styles["title-container"]}>
                  <div
                    className={styles["title"]}
                    tabIndex={0}
                  >
                    <span>{this.props.title}</span>
                  </div>
                </div>
                <div className={styles["button-container"]}>
                  <button
                    type="button"
                    className={styles["button"]}
                    onClick={this.close}
                    onKeyPress={this.close}
                  >
                    <Close className={styles["Close"]} />
                  </button>
                </div>
                <div className={styles["action-container"]}>
                  <div className={styles["action"]} />
                </div>
              </div>
              <div className={styles["main"]}>
                <div className={styles["main-content"]}>
                  <div className={styles["section-container-margin"]}>
                    <div className={styles["section-container"]}>
                      <section>{children}</section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.updateText ? (
              <div className={styles["footer"]}>
                <div className={styles["confirm-button-container"]}>
                  <button
                    style={{
                      background: !this.state.isUpdateButtonHovered
                        ? theme.theme.primaryColour
                        : theme.theme.hoverColour,
                    }}
                    data-cy={"modalUpdateButton"}
                    type="button"
                    onMouseEnter={() => {
                      this.setState({ isUpdateButtonHovered: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ isUpdateButtonHovered: false });
                    }}
                    className={styles["confirm-button"]}
                    onClick={() => {
                      this.finish();
                    }}
                    onKeyPress={this.finish}
                  >
                    <span className={styles["confirm-button-span"]}>
                      {this.props.updateText}
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Modal;
