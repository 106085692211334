import { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import _ from "lodash";
import { Popup } from "@urbica/react-map-gl";
import { Img } from "react-image";

import styles from "./MapMarkerPopup.module.scss";

const ReadMore = ({ children }) => {
  const htmlContent = children;
  // Sanitize the HTML content to strip out all HTML tags, leaving only plain text
  const cleanText = DOMPurify.sanitize(htmlContent, { ALLOWED_TAGS: [] });
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const renderContentWithBreaks = (full = false) => {
    // Replace escaped newlines with HTML line breaks before sanitizing
    const preProcessedHtml = htmlContent.replace(/\\n/g, "<br />");
    const allowedTags = ["br", "p"];
    const sanitizedHtml = DOMPurify.sanitize(preProcessedHtml, {
      ALLOWED_TAGS: allowedTags,
    });

    const contentToShow = full
      ? sanitizedHtml
      : `${sanitizedHtml.slice(0, 170)}...`;

    return { __html: contentToShow };
  };

  return (
    <p className="text">
      {cleanText.length > 170 ? (
        <>
          {isReadMore ? (
            <div dangerouslySetInnerHTML={renderContentWithBreaks()} />
          ) : (
            <div dangerouslySetInnerHTML={renderContentWithBreaks(true)} />
          )}
          <span
            onClick={toggleReadMore}
            className={styles["read-or-hide"]}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {isReadMore ? "Read more" : "Show less"}
          </span>
        </>
      ) : (
        <div dangerouslySetInnerHTML={renderContentWithBreaks(true)} />
      )}
    </p>
  );
};

const MapMarkerPopup = (props) => {
  const {
    event,
    destinationElement,
    travelPlanId,
    destinationGroup,
    selectedDateTime,
    updateViewPortByPopUp,
  } = props;

  const [showPopup, togglePopup] = useState(false);
  const [forceShowPopup, setForceShowPopup] = useState({
    ...props.forceShowPopup,
  });

  const ref = useRef();
  const tempTimeoutRef = useRef(null);

  const latitude =
    event?.accessPoints?.[0]?.location?.latitude ?? event?.location?.latitude;
  const longitude =
    event?.accessPoints?.[0]?.location?.longitude ?? event?.location?.longitude;

  useEffect(() => {
    togglePopup(true);
    if (tempTimeoutRef.current != null) clearTimeout(tempTimeoutRef.current);
    tempTimeoutRef.current = setTimeout(updateViewport, 400);
  }, [event, updateViewPortByPopUp]);

  // Runs after the first render() lifecycle
  function updateViewport() {
    updateViewPortByPopUp(
      //sum of outer frame and padding is 30px
      ref?.current?.getBoundingClientRect()?.height + 30,
      latitude,
      longitude
    );
  }

  useEffect(() => {
    if (forceShowPopup) {
      togglePopup(true);
    }
  }, [forceShowPopup]);

  useEffect(() => {
    setForceShowPopup(props.forceShowPopup);
  }, [props.forceShowPopup]);

  const includeInJourney = () => {
    if (customJson && customJson.button === "Include in journey") {
      if (destinationElement) {
        props.setChargeWayPoint(destinationElement.id);
      }
    }
  };

  function updateCloseCallBackFunction() {
    togglePopup(false);
    props.updateCloseCallBack();
  }

  const processMapMarkerLinkUrl = (linkUrl, options) => {
    if (!linkUrl || linkUrl === "") return linkUrl;
    if (!options || _.isEmpty(options)) return linkUrl;

    linkUrl += linkUrl.includes("?") ? "" : "?";

    if (options.addDestinationGroupName) {
      const key = options.addDestinationGroupNameKey ?? "ystid";
      const value = destinationElement.groupName;
      linkUrl += `&${key}=${value}`;
    }

    if (options.addEnvironmentName) {
      const key = options.addEnvironmentNameKey ?? "env";
      let url = window.location.href,
        startStr = "//",
        endStr = ".yousmartthing.com";
      let startIndex = url.indexOf(startStr),
        endIndex = url.indexOf(endStr);
      let value =
        startIndex > -1 && endIndex > -1
          ? url.substring(startIndex + startStr.length, endIndex)
          : "travel";
      linkUrl += `&${key}=${value}`;
    }

    return linkUrl;
  };

  const processMapMarker = (mapMarker) => {
    if (mapMarker === null) return mapMarker;
    mapMarker.linkUrl = processMapMarkerLinkUrl(
      mapMarker.linkUrl,
      mapMarker.linkUrlOptions
    );
    return mapMarker;
  };

  const createMapContent = () => {
    // Props 'image' and 'imageName' accommodate legacy data values.
    let legacyImageUrl =
      event && event.image
        ? event.image
        : destinationElement && destinationElement.image
        ? destinationElement.image
        : null;

    const mapContent =
      destinationElement && destinationElement.mapContent
        ? { ...destinationElement.mapContent }
        : event && event.mapContent
        ? { ...event.mapContent }
        : {
            mapMarker: {
              name: name,
              imageUrl: legacyImageUrl,
              linkUrl: "",
              linkUrlOptions: {},
              customHtml: "",
              customJson: {},
              tags: [],
            },
            confirmationPage: null,
            email: null,
            sms: null,
          };

    mapContent.mapMarker = processMapMarker(mapContent.mapMarker);

    return mapContent;
  };

  const name =
    destinationElement && destinationElement.imageName
      ? destinationElement.imageName
      : destinationElement.name;

  const availabilityText =
    event && event.status ? "(" + event.status + ")" : "";
  const mapContent = createMapContent();
  const { customHtml, imageUrl, linkUrl } = mapContent.mapMarker;

  var { customJson } = mapContent.mapMarker;
  if (
    (customJson === undefined || customJson === null) &&
    destinationGroup !== undefined &&
    destinationGroup !== null
  ) {
    customJson = destinationGroup.customJson;
  }

  const tipSize = event ? 5 : null;

  const renderImageUrl =
    imageUrl && imageUrl !== "" ? (
      <Img
        className={styles["pop-up-image"]}
        alt={name}
        width={220}
        src={imageUrl}
      />
    ) : (
      ""
    );

  function renderCustomJson() {
    return (
      customJson &&
      customJson.button && (
        <div className={styles["Button"]}>
          <div className={styles["button-container"]}>
            <button
              type="button"
              className={styles["button"]}
              onClick={() => includeInJourney()}
            >
              <span className={styles["button-span"]}>{customJson.button}</span>
            </button>
          </div>
        </div>
      )
    );
  }

  function renderTXGB() {
    return (
      event &&
      event.isTXGBavailable && (
        <button
          type="button"
          className={styles["button-txgb"]}
          onClick={() =>
            open(
              process.env.REACT_APP_TXGB_URL +
                destinationElement.uniqueIdentifier +
                process.env.REACT_APP_TXGB_URL_OPTIONS +
                selectedDateTime
            )
          }
        >
          <span className={styles["button-span"]}>Book a room</span>
        </button>
      )
    );
  }

  function open(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  function renderCustomHtml() {
    var content = "";
    if (travelPlanId != null) {
      if (customHtml != null && customHtml.includes("ystid")) {
        var myArr = customHtml.split("ystid");
        var myArr2 = myArr[1].split("'");
        myArr2.shift();
        const key = "travelPlanId";
        const value = travelPlanId;
        const key2 = "env";
        let url = window.location.href,
          startStr = "//",
          endStr = ".yousmartthing.com";
        let startIndex = url.indexOf(startStr),
          endIndex = url.indexOf(endStr);
        let value2 =
          startIndex > -1 && endIndex > -1
            ? url.substring(startIndex + startStr.length, endIndex)
            : "travel";
        myArr[0] += `&${key}=${value}&${key2}=${value2}''` + myArr2.join("'");
        content = myArr[0];
      } else {
        content = customHtml;
      }
    } else {
      content = customHtml;
    }

    return customHtml && customHtml !== "" ? (
      <div className={styles["pop-up-custom"]}>
        {destinationGroup.showReadMoreTag ? (
          <ReadMore>{customHtml}</ReadMore>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: customHtml }} />
        )}
      </div>
    ) : (
      ""
    );
  }

  return (
    <div>
      {showPopup && latitude && longitude && (
        <Popup
          className={styles["mapboxgl-popup"]}
          anchor="top"
          tipSize={tipSize}
          longitude={longitude}
          latitude={latitude}
          closeButton={true}
          closeOnClick={false}
          onClose={() => updateCloseCallBackFunction()}
        >
          <div ref={ref}>
            {destinationElement?.customURL &&
            destinationElement?.customURLPosition === "title" ? (
              <a
                href={destinationElement.customURL}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles["pop-up-inner"]}>
                  {destinationElement?.groupName === "EV Assist" ? "" : name}{" "}
                  {availabilityText}
                </div>{" "}
              </a>
            ) : (
              <div className={styles["pop-up-inner"]}>
                {destinationElement?.groupName === "EV Assist" ? "" : name}{" "}
                {availabilityText}
              </div>
            )}

            {linkUrl !== "" ? (
              <a
                href={linkUrl}
                target="_blank"
                rel="noreferrer"
              >
                {renderImageUrl}
              </a>
            ) : (
              renderImageUrl
            )}
            {renderCustomHtml()}
            {renderCustomJson()}
            {renderTXGB()}
          </div>
        </Popup>
      )}
    </div>
  );
};

export default MapMarkerPopup;
