import Modal from "../common/Modal";
import MarkerOptions from "../MarkerOptions/MarkerOptions";
import { useIntl } from "react-intl";

const MarkerModal = (props) => {
  const intl = useIntl();
  const {
    toggleModal,
    markerOptions,
    markerOptionsAction,
    isTrafficForecastAvailable,
    trafficForecast,
    switchTrafficForecast,
  } = props;

  return (
    <Modal
      title={intl.formatMessage({ id: "showOnMap" })}
      close={toggleModal}
      updateText={intl.formatMessage({ id: "apply" })}
      finish={toggleModal}
      shouldOverflow={true}
    >
      <MarkerOptions
        markerOptions={markerOptions}
        markerOptionsAction={markerOptionsAction}
        isTrafficForecastAvailable={isTrafficForecastAvailable}
        trafficForecast={trafficForecast}
        switchTrafficForecast={switchTrafficForecast}
      />
    </Modal>
  );
};

export default MarkerModal;
