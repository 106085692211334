import React, { Component } from "react";
import styles from "./CrossSiteWarning.module.scss";
import { ReactComponent as Close } from "../../assets/round-close-24px.svg";
import crossSiteImg from "../../assets/cross-site.png";

class CrossSiteWarning extends Component {
  handleWarningClose = () => {
    this.props.handleWarningClose();
  };

  render() {
    return (
      <div className={styles["Confirmation"]}>
        <div className={styles["outer-container"]}>
          <div className={styles["inner-container"]}>
            <div className={styles["button-container"]}>
              <button
                type="button"
                className={styles["button"]}
                onClick={this.handleWarningClose}
              >
                <Close className={styles["Close"]} />
              </button>
            </div>
            <div className={styles["no-margin"]}>
              <span className={styles["span"]}>
                Cross site tracking is disabled on your browser. In order access
                this feature, please enable it in browser preferences and
                refresh this page.
              </span>
              <div>
                <img
                  className={styles["cross-site-image"]}
                  border="0"
                  src={crossSiteImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CrossSiteWarning;
