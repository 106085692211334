import React, { Component } from "react";
import styles from "./Button.module.scss";
import { ThemeContext } from "../../../Context/ThemeContext";
class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonHovered: false,
    };
  }
  handleButtonClick = () => {
    this.props.handleButtonClick();
  };

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={styles["Button"]}>
            <div className={styles["button-container"]}>
              <button
                style={{
                  background: !this.state.isButtonHovered
                    ? this?.props?.visuallyDisabled
                      ? "#E0E0E0"
                      : theme.theme.primaryColour
                    : theme.theme.hoverColour,
                }}
                onMouseEnter={() => {
                  this.setState({ isButtonHovered: true });
                }}
                onMouseLeave={() => {
                  this.setState({ isButtonHovered: false });
                }}
                type="button"
                className={styles["button"]}
                onClick={this.handleButtonClick}
                aria-label={this.props.description}
                disabled={this?.props?.disabled}
              >
                <span
                  style={{
                    color:
                      !this?.props?.visuallyDisabled ||
                      this.state.isButtonHovered
                        ? "white"
                        : "#484848",
                  }}
                  className={styles["button-span"]}
                >
                  {this.props.text}
                </span>
              </button>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Button;
