import React, { Component } from "react";
import styles from "./Error.module.scss";
import { ReactComponent as Warning } from "../../assets/exclamation-circle-solid.svg";
import {
  genericErrorMessage,
  genericErrorType,
} from "../../../domain/constants/error";

class Error extends Component {
  render() {
    const { errorMessage, errorType } = this.props;
    return (
      <div className={styles["error-frame"]}>
        <div className={styles["error-outer-box"]}>
          <div className={styles["error-inner-box"]}>
            <div className={styles["error-content"]}>
              <span>{errorMessage ? errorMessage : genericErrorMessage}</span>
              <div className={styles["error-subtitle-container"]}>
                <span className={styles["error-subtitle"]}>
                  <span>
                    <Warning className={styles["Warning"]} />
                  </span>
                </span>
              </div>
              <div className={styles["error-subtitle-container"]}>
                <span className={styles["error-subtitle"]}>
                  {errorType ? errorType : genericErrorType}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
