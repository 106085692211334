import _ from "lodash";
import AuthProvider from "../Auth/authProviders/authProvider";

export default class AuthenticationService {
  //
  constructor(authProviderConfig) {
    for (const authProvider of authProviderConfig) {
      this._addAuthProvider(authProvider);
    }
  }

  _authProviders = [];

  // Public.

  authenticate = (id, token, callback) => {
    for (const item of this._authProviders) {
      if (item.id === id) {
        item.authenticate(token, callback);
        break;
      }
    }
  };

  // Private.

  _addAuthProvider = (authProvider) => {
    if (!(authProvider instanceof AuthProvider)) return;
    this._authProviders.push(authProvider);
  };

  _removeAuthProvider = (authProviderId) => {
    _.remove(this._authProviders, (item) => {
      return item.id === authProviderId;
    });
  };
}
