import React, { Component, useState } from "react";
import "./AdditionalServiceButton.css";

const SubmitButton = ({ icon, text, link, onClick }) => {
  const handleClick = () => {
    if (link) {
      window.open(link(), "_blank");
    } else if (onClick) {
      onClick();
    } else {
      alert("No link or onClick provided.");
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      className="submit-button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {icon && (
        <span
          style={{
            marginRight: "5px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <span
            style={{
              height: "20px",
              width: "20px",
              display: "inline-block",
            }}
          >
            <IconComponent
              icon={icon}
              isHovered={isHovered}
              color="#307e82"
              hoveredColor="#fff"
            />
          </span>
        </span>
      )}{" "}
      {text}
    </button>
  );
};

const IconComponent = ({
  icon,
  isHovered,
  color = "black",
  hoveredColor = "white",
}) => {
  const coloredIcon = React.cloneElement(icon, {
    style: { fill: !isHovered ? color : hoveredColor },
  });

  return <div>{coloredIcon}</div>;
};

export default SubmitButton;
