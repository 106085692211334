import MarkerGroup from "./MarkerGroup";
import PrimaryMarkerGroup from "./PrimaryMarkerGroup";
import LineMarkerGroup from "./LineMarkerGroup";
import ClusterHandler from "./ClusterHandler";

const MarkerGroups = ({
  _restServicePost,
  onClickCluster,
  queryString,
  showPrimaryGroup,
  primaryGroupShortCode,
  primaryGroupEvents,
  singleNonMDEvent,
  isMultiDestination,
  clusteringEnabled,
  markerOptions,
  setActiveEventSelectedOnMap,
  togglePolyPopUp,
  mapBounds,
  zoom,
}) => {
  var renderedBasicMarkerOptions = markerOptions.filter(
    (markerOption) =>
      markerOption.isFocused && markerOption.marker.type !== "Lines"
  );
  var renderedLineMarkerOptions = markerOptions.filter(
    (markerOption) =>
      markerOption.isFocused && markerOption.marker.type === "Lines"
  );

  return (
    <>
      {showPrimaryGroup && (
        <ClusterHandler onClickCluster={onClickCluster}>
          <PrimaryMarkerGroup
            isMultiDestination={isMultiDestination}
            singleNonMDEvent={singleNonMDEvent}
            clusteringEnabled={clusteringEnabled}
            setActiveEventSelectedOnMap={setActiveEventSelectedOnMap}
            events={primaryGroupEvents}
          />
        </ClusterHandler>
      )}
      {renderedBasicMarkerOptions.map((markerOption, index) => (
        <ClusterHandler
          key={markerOption.destinationGroupShortCode}
          onClickCluster={onClickCluster}
        >
          <MarkerGroup
            _restServicePost={_restServicePost}
            queryString={queryString}
            setActiveEventSelectedOnMap={setActiveEventSelectedOnMap}
            onClickCluster={onClickCluster}
            primaryGroupShortCode={primaryGroupShortCode}
            markerOption={markerOption}
            isMultiDestination={isMultiDestination}
            mapBounds={mapBounds}
            zoom={zoom}
          />
        </ClusterHandler>
      ))}
      {renderedLineMarkerOptions.map((markerOption, index) => (
        <LineMarkerGroup
          key={"line" + index}
          lineData={markerOption.marker.polyLine}
          togglePolyPopUp={togglePolyPopUp}
        />
      ))}
    </>
  );
};

export default MarkerGroups;
