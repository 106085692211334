import React, { Component } from "react";
import styles from "./TravelPlan.module.scss";
import moment from "moment";
import DOMPurify from "dompurify";
import { ReactComponent as Walk } from "../assets/round-directions_walk-24px.svg";
import { ReactComponent as Ferry } from "../assets/round-directions_boat-24px.svg";
import { ReactComponent as Rail } from "../assets/round-directions_railway-24px.svg";
import { ReactComponent as Bus } from "../assets/round-directions_bus-24px.svg";
import { ReactComponent as Tram } from "../assets/round-tram-24px.svg";
import { ReactComponent as Underground } from "../assets/round-directions_subway-24px.svg";
import { ReactComponent as ChevronRight } from "../assets/round-chevron_right-24px.svg";
import { ReactComponent as Drive } from "../assets/round-directions_car-24px.svg";
import { ReactComponent as Park } from "../assets/round-local_parking-24px.svg";
import { ReactComponent as EvStation } from "../assets/round-ev_station-24px.svg";
import { ReactComponent as Cycle } from "../assets/Bicycle.svg";
import LegCollection from "../LegCollection";
import userLocalDataService from "./../../services/data/userLocalDataService";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../Context/ThemeContext";

class TravelPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departing: false,
      dateInfo: moment.parseZone(),
      itinerary: [],
      description: "",
      message: "",
      active: false,
      duration: 0,
    };
  }

  handleJourneyLegClicked = (trackpoints) => {
    //this.props.onJourneyLegClicked(trackpoints);
  };

  toggleRequestAssistance = (travelForecastId) => {
    this.props.toggleRequestAssistance(travelForecastId);
  };

  // toggleHighlight = e => {
  //   e.stopPropagation();
  //   this.props.toggleHighlightedJourney(this.props.journey.order);
  //   this.props.onJourneyClicked(this.props.journey.legs);
  // };

  toggleClass = () => {
    if (this.state.active === false) {
      this.props.toggleActiveTravelPlan(null);
    } else {
      this.props.toggleActiveTravelPlan(this.props.order);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  componentDidMount = () => {
    var selectedJourneyOption =
      this.props.journeyResponse.journeyOptions.filter(
        (journeyOption) =>
          journeyOption.id === this.props.travelForecast.journeyOptionId
      )[0];
    // User selected journey option is shown on the selected journeyOption

    var dateInfo;
    if (this.props.journeyRequest.length - 1 > 0) {
      this.props.journeyRequest[0].departureTime !== undefined
        ? (dateInfo = new Date(this.props.journeyRequest[0].departureTime))
        : (dateInfo = new Date(this.props.journeyRequest[0].arrivalTime));
    } else {
      this.props.journeyRequest.departureTime !== undefined
        ? (dateInfo = new Date(this.props.journeyRequest.departureTime))
        : (dateInfo = new Date(this.props.journeyRequest.arrivalTime));
    }
    //
    // this.props.journeyRequest.departureTime !== undefined
    //   ? new Date(this.props.journeyRequest.departureTime)
    //   : new Date(this.props.journeyRequest.arrivalTime);

    var departing =
      this.props.journeyRequest.departureTime !== undefined ? true : false;

    var itinerary = [];
    const legsCount = selectedJourneyOption.legs.length - 1;
    if (selectedJourneyOption === this.props.activeJourney) {
      this.props.toggleHighlightedJourney(selectedJourneyOption.order);
    }
    selectedJourneyOption.legs.forEach(function (leg, index) {
      if (legsCount !== index) {
        itinerary.push(
          {
            Walk: (
              <span key={index}>
                <Walk className={styles["Walk"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Rail: (
              <span key={index}>
                <Rail className={styles["Rail"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Bus: (
              <span key={index}>
                <Bus className={styles["Bus"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Ferry: (
              <span key={index}>
                <Ferry className={styles["Ferry"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Tram: (
              <span key={index}>
                <Tram className={styles["Tram"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Coach: (
              <span key={index}>
                <Bus className={styles["Coach"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Metro: (
              <span key={index}>
                <Tram className={styles["Metro"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Underground: (
              <span key={index}>
                <Underground className={styles["Underground"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            ElectricVehicle: (
              <span key={index}>
                <Drive className={styles["Drive"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Drive: (
              <span key={index}>
                <Drive className={styles["Drive"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Park: (
              <span key={index}>
                <Park className={styles["Park"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            EvStation: (
              <span key={index}>
                <EvStation className={styles["EvStation"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
            Cycle: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
                <ChevronRight className={styles["ChevronRight"]} />
              </span>
            ),
          }[leg.mode]
        );
      } else {
        itinerary.push(
          {
            Walk: (
              <span key={index}>
                <Walk key={index} className={styles["Walk"]} />
              </span>
            ),
            Rail: (
              <span key={index}>
                <Rail key={index} className={styles["Rail"]} />
              </span>
            ),
            Bus: (
              <span key={index}>
                <Bus key={index} className={styles["Bus"]} />
              </span>
            ),
            Ferry: (
              <span key={index}>
                <Ferry key={index} className={styles["Ferry"]} />
              </span>
            ),
            Tram: (
              <span key={index}>
                <Tram key={index} className={styles["Tram"]} />
              </span>
            ),
            Coach: (
              <span key={index}>
                <Bus key={index} className={styles["Coach"]} />
              </span>
            ),
            Metro: (
              <span key={index}>
                <Tram key={index} className={styles["Metro"]} />
              </span>
            ),
            Underground: (
              <span key={index}>
                <Underground key={index} className={styles["Underground"]} />
              </span>
            ),
            Drive: (
              <span key={index}>
                <Drive key={index} className={styles["Drive"]} />
              </span>
            ),
            ElectricVehicle: (
              <span key={index}>
                <Drive key={index} className={styles["Drive"]} />
              </span>
            ),
            Park: (
              <span key={index}>
                <Park key={index} className={styles["Park"]} />
              </span>
            ),
            EvStation: (
              <span key={index}>
                <EvStation key={index} className={styles["EvStation"]} />
              </span>
            ),
            Cycle: (
              <span key={index}>
                <Cycle className={styles["Cycle"]} />
              </span>
            ),
          }[leg.mode]
        );
      }
    });

    this.setState({
      itinerary: itinerary,
      description: selectedJourneyOption.description,
      message: selectedJourneyOption.message,
      dateInfo: moment.parseZone(dateInfo),
      departing,
      duration: selectedJourneyOption.journeyDuration,
      selectedJourneyId: selectedJourneyOption.id,
      legs: selectedJourneyOption.legs,
      active: this.props.active,
    });
  };

  render() {
    const {
      itinerary,
      description,
      message,
      dateInfo,
      departing,
      duration,
      active,
    } = this.state;

    const apimUser = userLocalDataService.getAPIMUser();

    var journeyOption = this.props.journeyResponse.journeyOptions.filter(
      (journeyOption) =>
        journeyOption.id === this.props.travelForecast.journeyOptionId
    )[0];

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <li
            onClick={this.toggleHighlight}
            className={`${styles["Journey"]} ${styles["highlight"]}`}
          >
            <div className={styles["journey-padding"]}>
              <div className={styles["journey-sub"]}>
                <div className={styles["journey-title-outer-container"]}>
                  <div className={styles["journey-title-inner-container"]}>
                    <div className={styles["journey-title"]}>
                      <span className={styles["travelPlan-title"]}>
                        {dateInfo.local().format("Do MMMM")}
                        {departing ? " departing " : " arriving "}
                        {dateInfo.local().format("HH:mm")}
                      </span>
                      <div className={styles["travelPlan-locations"]}>
                        <span>
                          From:{" "}
                          {/* {this.props.journeyRequest.startLocation.description} */}
                          {
                            this.props.journeyResponse.journeyOptions.filter(
                              (journeyOption) =>
                                journeyOption.id ===
                                this.props.travelForecast.journeyOptionId
                            )[0].startLocation.description
                          }
                        </span>
                        <br></br>
                        <span>
                          To:{" "}
                          {/* {this.props.journeyRequest.endLocation.description} */}
                          {/* {this.props.journeyRequest.journeyOption.endLocation.description}  */}
                          {
                            this.props.journeyResponse.journeyOptions.filter(
                              (journeyOption) =>
                                journeyOption.id ===
                                this.props.travelForecast.journeyOptionId
                            )[0].endLocation.description
                          }
                        </span>
                      </div>

                      <span>{description}</span>
                      <div className={styles["journey-subtitle-container"]}>
                        <span className={styles["journey-subtitle"]}>
                          {itinerary}
                        </span>
                      </div>
                      <div className={styles["journey-subtitle-container"]}>
                        <span className={styles["journey-subtitle"]}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(message, {
                                ADD_ATTR: ["target"],
                                // sanitize: true, TODO: Re-implement this!
                              }),
                            }}
                          />
                        </span>
                      </div>
                      <div
                        className={`${styles["journey-subtitle-container"]} ${styles["journey-subtitle-margin"]}`}
                      >
                        <span className={styles["journey-subtitle"]}>
                          <FormattedMessage id="notifiedTo" />
                          {this.props.travelForecast.userNotificationMail ===
                          undefined
                            ? apimUser.identityEmail
                            : this.props.travelForecast.userNotificationMail}
                        </span>
                      </div>
                      <div className={styles["journey-subtitle-container"]}>
                        <span className={styles["journey-subtitle-a"]}>
                          <button
                            className={styles["a"]}
                            onClick={this.toggleClass}
                          >
                            {active ? "Hide details" : "Show details"}
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles["journey-detail-outer-container"]}>
                  <div className={styles["journey-detail-inner-container"]}>
                    <div className={styles["journey-detail"]}>
                      {Math.floor(duration / 60) === 0
                        ? ""
                        : Math.floor(duration / 60) + " h"}{" "}
                      {duration % 60 === 0 ? "" : (duration % 60) + " min"}
                    </div>
                  </div>
                </div>
              </div>
              <LegCollection
                active={active}
                travelForecast={this.props.travelForecast}
                legs={journeyOption.legs}
                fares={journeyOption.fares}
                onJourneyLegClicked={this.handleJourneyLegClicked}
                apiToken={this.props.apiToken}
                auth={this.props.auth}
                toggleRequestAssistance={this.toggleRequestAssistance}
              />
              {this.props.assistanceSettings.contacts[0].name !== null ? (
                <div className={styles["copy-button-container"]}>
                  <button
                    type="button"
                    className={styles["confirm-button"]}
                    onClick={() =>
                      this.props.getPreviousTravelPlan(
                        this.props.travelForecast.id,
                        this.props.travelForecast.destinationGroupName
                      )
                    }
                  >
                    <span className={styles["confirm-button-span"]}>
                      <FormattedMessage id="editTrip" />
                    </span>
                  </button>
                  <button
                    type="button"
                    className={styles["confirm-assistance-button"]}
                    onClick={() =>
                      this.props.toggleRequestAssistance(
                        this.props.travelForecast.id
                      )
                    }
                  >
                    <span className={styles["confirm-assistance-button-span"]}>
                      <FormattedMessage id="requestAssistance" />
                    </span>
                  </button>
                </div>
              ) : this.props.destinationGroupAssistanceSettings !==
                undefined ? (
                <div className={styles["copy-button-container"]}>
                  <button
                    type="button"
                    className={styles["confirm-button"]}
                    onClick={() =>
                      this.props.getPreviousTravelPlan(
                        this.props.travelForecast.id,
                        this.props.travelForecast.destinationGroupName
                      )
                    }
                  >
                    <span className={styles["confirm-button-span"]}>
                      <FormattedMessage id="editTrip" />
                    </span>
                  </button>
                  <button
                    type="button"
                    className={styles["confirm-assistance-button"]}
                    onClick={() =>
                      this.props.toggleRequestAssistance(
                        this.props.travelForecast.id
                      )
                    }
                  >
                    <span className={styles["confirm-assistance-button-span"]}>
                      <FormattedMessage id="requestAssistance" />
                    </span>
                  </button>
                </div>
              ) : (
                <div className={styles["copy-button-container"]}>
                  <button
                    type="button"
                    style={{ background: theme.theme.primaryColour }}
                    className={styles["confirm-button-no-assistance"]}
                    onClick={() =>
                      this.props.getPreviousTravelPlan(
                        this.props.travelForecast.id,
                        this.props.travelForecast.destinationGroupName
                      )
                    }
                  >
                    <span className={styles["confirm-button-span"]}>
                      <FormattedMessage id="editTrip" />
                    </span>
                  </button>
                </div>
              )}
            </div>
          </li>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default TravelPlan;
