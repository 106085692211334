import React, { Component } from "react";
import styles from "./InfoPopup.module.scss";
class InfoPopup extends Component {
  handlePopupClose = () => {
    this.props.handlePopupClose();
  };

  render() {
    const title = "Warning!";
    const text = this.props.popUpContent;

    return (
      <div className={styles["modal"]}>
        <div className={styles["modal-content"]}>
          <span className={styles["close"]} onClick={this.handlePopupClose}>
            &times;
          </span>

          <section>
            <h2 tabIndex="-1" className={styles["h2"]}>
              <div className={styles["header-content"]}>
                <div className={styles["header"]}>{title}</div>
              </div>
            </h2>
          </section>

          <div className={styles["pre-outer-container"]}>
            <div className={styles["pre-mobile-container"]}>
              <div className={styles["pre-inner-container"]}>
                <pre className={styles["pre"]}>
                  <div>{text}</div>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoPopup;
