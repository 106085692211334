import { Component } from "react";
import styles from "./RequestAssistance.module.scss";
import { ReactComponent as Close } from "../../assets/round-close-24px.svg";
import TravelForecastsRestService from "./../../../services/rest/travelForecastsRestService";
import { FormattedMessage } from "react-intl";

const travelForecastsRestService = TravelForecastsRestService();
class RequestAssistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editRequestAssistance: false,
      profileTitle: "Assistance Confirmation",
      submitted: false,
      apiToken: null,
      destinationContacts: null,
      showPendingAssistance: null,
    };
  }

  componentDidMount = () => {
    if (this.props.showRequestAssistance === true) {
      this.handleShowRequestAssistance();
      this.setState({
        editRequestAssistance: this.props.showRequestAssistance,
      });
    }
    if (this.props.apiToken !== null) {
      this.setState({ apiToken: this.props.apiToken });
    }
    if (this.props.destinationGroupAssistanceSettings !== undefined) {
      if (this.props.destinationGroupAssistanceSettings.contacts.length !== 0) {
        this.setState({
          destinationContacts:
            this.props.destinationGroupAssistanceSettings.contacts,
        });
      }
    }
    this.setState({ showPendingAssistance: this.props.showPendingAssistance });
  };

  close = () => {
    if (this.state.editRequestAssistance) {
      this.handleHideEditAssistance();
      this.props.showRequestAssistance();
    }
    this.props.showRequestAssistance();
  };

  logout = () => {
    let logout = window.confirm("Do you want to log out from Travel Assistant");
    if (logout) this.props.logout();
  };

  handleHideEditAssistance = () => {
    this.setState({
      editRequestAssistance: false,
    });
  };

  toggleConfirmationDestinationGroup = (destinationContact) => {
    const callRequestAssistance = async () => {
      try {
        const requestorInformation = JSON.parse(localStorage.getItem("user"));
        const assistanceSettings = {
          contacts: [
            {
              ...destinationContact,
            },
          ],
          requestorInformation: requestorInformation,
          isDestinationGroupAssistant: true,
        };
        const dto = {
          id: this.props.travelForecastId,
          assistanceSettings: assistanceSettings,
          ...this.props.currentPosition,
        };
        await travelForecastsRestService.requestAssistance(dto);
        this.props.toggleConfirmation();
      } catch (error) {
        console.log(error);
      }
    };
    this.setState({ submitted: true }, callRequestAssistance);
  };

  toggleConfirmation = () => {
    const callRequestAssistance = async () => {
      try {
        const requestorInformation = JSON.parse(localStorage.getItem("user"));
        const assistanceSettings = {
          ...this.props.assistanceSettings,
          requestorInformation: requestorInformation,
          isDestinationGroupAssistant: false,
        };
        const dto = {
          id: this.props.travelForecastId,
          assistanceSettings: assistanceSettings,
          latitude: this.props.currentPosition.latitude,
          longitude: this.props.currentPosition.longitude,
        };
        await travelForecastsRestService.requestAssistance(dto);
        this.props.toggleAssistanceProfileOnConfirmation();
      } catch (error) {
        console.log(error);
      }
    };
    this.setState({ submitted: true }, callRequestAssistance);
  };

  siteCallConfirmation = (siteCallUrl) => {
    var url =
      siteCallUrl +
      `${window.location.origin}/${this.props.destinationGroup}/travelplan?travelplanid=${this.props.travelForecastId}%26assist=true`;
    window.open(url, "_blank");
  };

  isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  render() {
    const { profileTitle, destinationContacts, showPendingAssistance } =
      this.state;
    return (
      <div className={styles["Modal"]}>
        <div className={styles["content"]} role="dialog">
          <div className={styles["header"]}>
            <div className={styles["button-container"]}>
              <button
                type="button"
                className={styles["button"]}
                onClick={this.close}
              >
                <Close className={styles["Close"]} />
              </button>
            </div>

            <div className={styles["title-container"]}>
              <div className={styles["title"]}>
                <span>{profileTitle}</span>
              </div>
            </div>

            <div className={styles["action-container"]}>
              <div className={styles["action"]} />
            </div>
          </div>
          <div className={styles["main"]}>
            <div className={styles["filter-button-container"]}>
              {this.props.assistanceSettings.contacts[0].email !== null ? (
                <div className={styles["assistance-padding"]}>
                  <div className={styles["assistance-sub"]}>
                    <div className={styles["assistance-title-outer-container"]}>
                      <div
                        className={styles["assistance-title-inner-container"]}
                      >
                        <div className={styles["assistance-title"]}>
                          <span className={styles["assistance-title-copy"]}>
                            Nominated Assistance Contact
                          </span>
                          <div className={styles["assistance-details"]}>
                            <span>
                              Contact:
                              <div className={styles["assistance-information"]}>
                                {this.props.assistanceSettings.contacts[0].name
                                  ? " " +
                                    this.props.assistanceSettings.contacts[0]
                                      .name
                                  : " "}
                              </div>
                            </span>
                            <br></br>
                            <span>
                              Mobile Number:
                              {
                                <div
                                  className={styles["assistance-information"]}
                                >
                                  {this.props.assistanceSettings.contacts[0]
                                    .phone
                                    ? " " +
                                      this.props.assistanceSettings.contacts[0]
                                        .phone
                                    : " "}
                                </div>
                              }
                            </span>
                            <br></br>
                            <span>
                              <FormattedMessage id="notifiedTo" />
                              {
                                <div
                                  className={styles["assistance-information"]}
                                >
                                  {this.props.assistanceSettings.contacts[0]
                                    .email
                                    ? " " +
                                      this.props.assistanceSettings.contacts[0]
                                        .email
                                    : " "}
                                </div>
                              }
                            </span>
                          </div>
                          <br></br>
                          {showPendingAssistance ? (
                            <button
                              type="button"
                              className={styles["pending-button"]}
                              disabled={true}
                            >
                              <span className={styles["pending-button-span"]}>
                                Confirmation pending
                              </span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className={styles["assistance-button"]}
                              onClick={this.toggleConfirmation}
                            >
                              <span
                                className={styles["assistance-button-span"]}
                              >
                                Confirm assistance request
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
              ) : (
                ""
              )}
              {destinationContacts !== null ? (
                <div>
                  {destinationContacts.map((destinationContact, index) => (
                    <div className={styles["assistance-padding"]}>
                      <div className={styles["assistance-sub"]}>
                        <div
                          className={styles["assistance-title-outer-container"]}
                        >
                          <div
                            className={
                              styles["assistance-title-inner-container"]
                            }
                          >
                            <div className={styles["assistance-title"]}>
                              <span className={styles["assistance-title-copy"]}>
                                {destinationContact.name}
                              </span>
                              <div className={styles["assistance-details"]}>
                                {destinationContact.description ? (
                                  <span>
                                    Operator:
                                    <div
                                      className={
                                        styles["assistance-information"]
                                      }
                                    >
                                      {destinationContact.description
                                        ? " " + destinationContact.description
                                        : " "}
                                    </div>
                                  </span>
                                ) : (
                                  ""
                                )}
                                <br></br>
                                {destinationContact.email ? (
                                  <span>
                                    <FormattedMessage id="notifiedTo" />
                                    <div
                                      className={
                                        styles["assistance-information"]
                                      }
                                    >
                                      {destinationContact.email
                                        ? " " + destinationContact.email
                                        : " "}
                                    </div>
                                  </span>
                                ) : (
                                  ""
                                )}
                                <br></br>
                                {destinationContact.phone ? (
                                  <span>
                                    Mobile Number:
                                    <div
                                      className={
                                        styles["assistance-information"]
                                      }
                                    >
                                      {destinationContact.phone
                                        ? " " + destinationContact.phone
                                        : " "}
                                    </div>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <br></br>
                              {destinationContact.sightCallEnabled ===
                              "true" ? (
                                <button
                                  type="button"
                                  className={styles["assistance-button"]}
                                  onClick={this.siteCallConfirmation.bind(
                                    this,
                                    destinationContact.sightCallUrl
                                  )}
                                >
                                  <span
                                    className={styles["assistance-button-span"]}
                                  >
                                    Confirm assistance request
                                  </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className={styles["assistance-button"]}
                                  onClick={() =>
                                    this.toggleConfirmationDestinationGroup(
                                      destinationContact
                                    )
                                  }
                                >
                                  <span
                                    className={styles["assistance-button-span"]}
                                  >
                                    Confirm assistance request
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestAssistance;
