import styles from "./EditElectricVehicles.module.scss";
import { ReactComponent as Minus } from "../assets/minus_passive.svg";
import { ReactComponent as MinusActive } from "../assets/minus_active.svg";
import { ReactComponent as Plus } from "../assets/plus_passive.svg";
import { ReactComponent as PlusActive } from "../assets/plus_active.svg";
import { ReactComponent as ComboCCS } from "../assets/combo_unselected.svg";
import { ReactComponent as ComboCCSActive } from "../assets/combo_selected.svg";
import { ReactComponent as Chademo } from "../assets/chademo_unselected.svg";
import { ReactComponent as ChademoActive } from "../assets/chademo_selected.svg";
import { ReactComponent as RapidAC43 } from "../assets/ac43_unselected.svg";
import { ReactComponent as RapidAC43Active } from "../assets/ac43_selected.svg";
import { useIntl } from "react-intl";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContext } from "react";
import styled from "styled-components";

const EditElectricVehicles = (props) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  const {
    handleRemainingRangeFilterQuantity,
    handleMaximumRangeFilterQuantity,
    handleChargingTimeFilterQuantity,
  } = props;
  const { handleChademoFilter, handleComboCCSFilter, handleRapidAC43Filter } =
    props;

  const { filterRemainingRange, filterMaximumRange, filterChargingTime } =
    props;
  const { filterChademo, filterComboCCS, filterRapidAC43 } = props;

  const StyledMinusActive = styled(MinusActive)`
    circle,
    path {
      fill: ${theme.theme.primaryColour} !important;
    }
  `;

  const StyledPlusActive = styled(PlusActive)`
    circle,
    path {
      fill: ${theme.theme.primaryColour} !important;
    }
  `;

  return (
    <div>
      <div
        style={{
          background: filterRemainingRange > 0 ? theme.theme.primaryColour : "",
        }}
        className={`${styles["numeric-button-container"]} ${
          filterRemainingRange > 0 ? styles["active"] : styles["inactive"]
        }`}
      >
        <div
          className={styles["numeric-button-text-header"]}
          tabIndex="0"
        >
          {filterRemainingRange > 1 ? (
            <p>{intl.formatMessage({ id: "remainingRange" })}</p>
          ) : (
            <p>{intl.formatMessage({ id: "remainingRange" })}</p>
          )}
        </div>
        <div className={styles["numeric-button-container-icon-box"]}>
          <div
            className={styles["numeric-button-sign-container"]}
            onKeyPress={() => handleRemainingRangeFilterQuantity(-5)}
            onClick={() => handleRemainingRangeFilterQuantity(-5)}
            tabIndex="0"
            title="Reduce the number of remaining range by five"
          >
            {filterRemainingRange > 0 ? (
              <StyledMinusActive
                className={styles["numeric-button-container-minus"]}
              />
            ) : (
              <Minus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-minus"]}
              />
            )}
          </div>
          <span className={styles["numeric-button-container-middle-text"]}>
            {filterRemainingRange > 1 ? (
              <p> {filterRemainingRange}</p>
            ) : (
              <p> {filterRemainingRange} </p>
            )}
          </span>
          <div
            className={styles["numeric-button-sign-container"]}
            onKeyPress={() => handleRemainingRangeFilterQuantity(5)}
            onClick={() => handleRemainingRangeFilterQuantity(5)}
            tabIndex="0"
            title="Increase the number of remaining range by five"
          >
            {filterRemainingRange > 0 ? (
              <StyledPlusActive
                className={styles["numeric-button-container-plus"]}
              />
            ) : (
              <Plus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-plus"]}
              />
            )}
          </div>
        </div>
        <div
          className={styles["numeric-button-text-box"]}
          tabIndex="0"
        >
          {filterRemainingRange > 1 ? (
            <p> {theme.isKilometres ? "Kilometres" : "Miles"}</p>
          ) : (
            <p> {theme.isKilometres ? "Kilometre" : "Mile"} </p>
          )}
        </div>
      </div>
      <div
        style={{
          background: filterMaximumRange > 0 ? theme.theme.primaryColour : "",
        }}
        className={`${styles["numeric-button-container-right"]} ${
          filterMaximumRange > 0 ? styles["active"] : styles["inactive"]
        }`}
      >
        <div
          className={styles["numeric-button-text-header"]}
          tabIndex="0"
        >
          {filterMaximumRange > 1 ? (
            <p>{intl.formatMessage({ id: "maximumRange" })}</p>
          ) : (
            <p>{intl.formatMessage({ id: "maximumRange" })}</p>
          )}
        </div>
        <div className={styles["numeric-button-container-icon-box"]}>
          <div
            className={styles["numeric-button-sign-container"]}
            onClick={() => handleMaximumRangeFilterQuantity(-5)}
            onKeyPress={() => handleMaximumRangeFilterQuantity(-5)}
            tabIndex="0"
            title="Reduce the number of maximum range by five"
          >
            {filterMaximumRange > 0 ? (
              <StyledMinusActive
                className={styles["numeric-button-container-minus"]}
              />
            ) : (
              <Minus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-minus"]}
              />
            )}
          </div>
          <span className={styles["numeric-button-container-middle-text"]}>
            {filterMaximumRange > 1 ? (
              <p> {filterMaximumRange}</p>
            ) : (
              <p> {filterMaximumRange} </p>
            )}
          </span>

          <div
            className={styles["numeric-button-sign-container"]}
            onClick={() => handleMaximumRangeFilterQuantity(5)}
            onKeyPress={() => handleMaximumRangeFilterQuantity(5)}
            tabIndex="0"
            title="Increase the number of maximum range by five"
          >
            {filterMaximumRange > 0 ? (
              <StyledPlusActive
                className={styles["numeric-button-container-plus"]}
              />
            ) : (
              <Plus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-plus"]}
              />
            )}
          </div>
        </div>
        <div
          className={styles["numeric-button-text-box"]}
          tabIndex="0"
        >
          {filterMaximumRange > 1 ? (
            <p>{theme.isKilometres ? "Kilometres" : "Miles"}</p>
          ) : (
            <p> {theme.isKilometres ? "Kilometre" : "Mile"} </p>
          )}
        </div>
      </div>
      <div
        style={{
          background: filterChargingTime > 0 ? theme.theme.primaryColour : "",
        }}
        className={`${styles["numeric-button-container"]} ${
          filterChargingTime > 0 ? styles["active"] : styles["inactive"]
        }`}
      >
        <div
          className={styles["numeric-button-text-header"]}
          tabIndex="0"
        >
          {filterRemainingRange > 1 ? (
            <p>{intl.formatMessage({ id: "chargingTime" })}</p>
          ) : (
            <p>{intl.formatMessage({ id: "chargingTime" })}</p>
          )}
        </div>
        <div className={styles["numeric-button-container-icon-box"]}>
          <div
            className={styles["numeric-button-sign-container"]}
            onKeyPress={() => handleChargingTimeFilterQuantity(-5)}
            onClick={() => handleChargingTimeFilterQuantity(-5)}
            tabIndex="0"
            title="Reduce the number of charging time by five"
          >
            {filterChargingTime > 0 ? (
              <StyledMinusActive
                className={styles["numeric-button-container-minus"]}
              />
            ) : (
              <Minus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-minus"]}
              />
            )}
          </div>
          <span className={styles["numeric-button-container-middle-text"]}>
            {filterChargingTime > 1 ? (
              <p> {filterChargingTime}</p>
            ) : (
              <p> {filterChargingTime} </p>
            )}
          </span>

          <div
            className={styles["numeric-button-sign-container"]}
            onKeyPress={() => handleChargingTimeFilterQuantity(5)}
            onClick={() => handleChargingTimeFilterQuantity(5)}
            tabIndex="0"
            title="Increase the number of charging time by five"
          >
            {filterChargingTime > 0 ? (
              <StyledPlusActive
                className={styles["numeric-button-container-plus"]}
              />
            ) : (
              <Plus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-plus"]}
              />
            )}
          </div>
        </div>
        <div
          className={styles["numeric-button-text-box"]}
          tabIndex="0"
        >
          {filterChargingTime > 1 ? <p>Minutes </p> : <p> Minute </p>}
        </div>
      </div>
      <div
        onClick={handleChademoFilter}
        onKeyPress={handleChademoFilter}
        style={{
          background: filterChademo > 0 ? theme.theme.primaryColour : "",
        }}
        className={`${styles["numeric-button-container-right"]} ${
          filterChademo ? styles["active"] : styles["inactive"]
        }`}
        tabIndex="0"
      >
        {filterChademo > 0 ? (
          <div className={styles["button-container-icon-box"]}>
            <ChademoActive
              className={styles["numeric-button-container-icon"]}
            />
          </div>
        ) : (
          <div className={styles["button-container-icon-box"]}>
            <Chademo className={styles["numeric-button-container-icon"]} />
          </div>
        )}
        <div className={styles["numeric-button-text-box"]}>
          <p className={styles["numeric-button-text"]}>
            <p>{intl.formatMessage({ id: "cHAdeMO" })}</p>
          </p>
        </div>
      </div>
      <div
        onClick={handleComboCCSFilter}
        onKeyPress={handleComboCCSFilter}
        style={{
          background: filterComboCCS ? theme.theme.primaryColour : "",
        }}
        className={`${styles["numeric-button-container"]} ${
          filterComboCCS ? styles["active"] : styles["inactive"]
        }`}
        tabIndex="0"
      >
        {filterComboCCS > 0 ? (
          <div className={styles["button-container-icon-box"]}>
            <ComboCCSActive
              className={styles["numeric-button-container-icon"]}
            />
          </div>
        ) : (
          <div className={styles["button-container-icon-box"]}>
            <ComboCCS className={styles["numeric-button-container-icon"]} />
          </div>
        )}
        <div className={styles["numeric-button-text-box"]}>
          <p className={styles["numeric-button-text"]}>
            <p>{intl.formatMessage({ id: "comboCCS" })}</p>
          </p>
        </div>
      </div>
      <div
        onClick={handleRapidAC43Filter}
        onKeyPress={handleRapidAC43Filter}
        style={{
          background: filterRapidAC43 > 0 ? theme.theme.primaryColour : "",
        }}
        className={`${styles["numeric-button-container-right"]} ${
          filterRapidAC43 ? styles["active"] : styles["inactive"]
        }`}
        tabIndex="0"
      >
        {filterRapidAC43 > 0 ? (
          <div className={styles["button-container-icon-box"]}>
            <RapidAC43Active
              className={styles["numeric-button-container-icon"]}
            />
          </div>
        ) : (
          <div className={styles["button-container-icon-box"]}>
            <RapidAC43 className={styles["numeric-button-container-icon"]} />
          </div>
        )}
        <div className={styles["numeric-button-text-box"]}>
          <p className={styles["numeric-button-text"]}>
            <p>{intl.formatMessage({ id: "rapidAC43" })}</p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditElectricVehicles;
