import { createContext, useState } from "react";

const defaultTheme = {
  primaryColour: "#008489",
  hoverColour: "#00B9BF",
  polylineColour: "#008489",
  activePolylineColour: "#FF5733",
  footer: {},
};

export const ThemeContext = createContext({
  theme: defaultTheme,
  setTheme: () => {},
  isKilometres: false,
  setIsKilometres: () => {},
  hideClientElements: false,
  setHideClientElements: () => {},
  getTravelForecastText: "",
  setGetTravelForecastText: () => {},
  expandPTOptions: true,
  setExpandPTOptions: () => {},
  showReturnButton: false,
  setShowReturnButton: () => {},
  carbonRemovalValues: null,
  setCarbonRemovalValues: () => {},
});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);
  const [isKilometres, setIsKilometres] = useState(false);
  const [hideClientElements, setHideClientElements] = useState([]);
  const [getTravelForecastText, setGetTravelForecastText] = useState("");
  const [expandPTOptions, setExpandPTOptions] = useState(true);
  const [showReturnButton, setShowReturnButton] = useState(false);
  const [carbonRemovalValues, setCarbonRemovalValues] = useState(null);

  const updateTheme = (newTheme) => {
    setTheme({ ...theme, ...newTheme });
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: updateTheme,
        isKilometres,
        setIsKilometres: setIsKilometres,
        hideClientElements,
        setHideClientElements: setHideClientElements,
        getTravelForecastText,
        setGetTravelForecastText,
        expandPTOptions,
        setExpandPTOptions,
        showReturnButton,
        setShowReturnButton,
        carbonRemovalValues,
        setCarbonRemovalValues,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
