import localDataService from "./data/localDataService";
import { GeolocationData } from "../domain/models";

const key = "geolocation";
let _watchId: number | null = null;

const _fetchCurrentPosition = (options: any) => {
  options = { ...options, enableHighAccuracy: true, timeout: 15000 };

  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
};

const _storeCurrentPosition = (position: any) => {
  const coords = position.coords;
  const value = {
    accuracy: coords.accuracy,
    altitude: coords.altitude,
    altitudeAccuracy: coords.altitudeAccuracy,
    heading: coords.heading,
    latitude: coords.latitude,
    longitude: coords.longitude,
    speed: coords.speed,
    timestamp: position.timestamp,
  };
  localDataService.setItem(key, value as GeolocationData);
};

const disableWatchPosition = () => {
  if (_watchId !== null) {
    navigator.geolocation.clearWatch(_watchId);
    _watchId = null;
  }
};

const dispose = () => {
  localDataService.removeItem(key);
  disableWatchPosition();
};

const enableWatchPosition = (
  successCallback: Function,
  errorCallback: Function,
  options: PositionOptions
) => {
  if (_watchId !== null) return;
  options = { ...options, enableHighAccuracy: true };

  _watchId = navigator.geolocation.watchPosition(
    (position) => {
      _storeCurrentPosition(position);
      if (typeof successCallback === "function") successCallback(position);
    },
    (error) => {
      if (typeof errorCallback === "function") errorCallback(error);
    },
    options
  );
};

const fetchCurrentPosition = async (
  successCallback: Function,
  errorCallback: Function,
  options: PositionOptions
) => {
  try {
    const position = await _fetchCurrentPosition(options);
    _storeCurrentPosition(position);
    if (typeof successCallback === "function") successCallback(position);
    return position;
  } catch (error) {
    if (typeof errorCallback === "function") errorCallback(error);
    return null;
  }
};

const getCurrentPosition = (): GeolocationData => {
  return localDataService.getItem(key, JSON.parse);
};

const geolocationService = {
  disableWatchPosition,
  dispose,
  enableWatchPosition,
  fetchCurrentPosition,
  getCurrentPosition,
};

export default geolocationService;
