import { PureComponent } from "react";

import roadClosedOn from "../../components/assets/road_closed_on.svg";

export default class ClusteringTrafficLocationOn extends PureComponent {
  render() {
    const { onClick } = this.props;
    var tempIcon = "";

    tempIcon = (
      <img
        tabIndex="1"
        role="button"
        src={roadClosedOn}
        width="30px"
        height="30px"
        alt={"Road Closed"}
        onClick={onClick}
      ></img>
    );

    return tempIcon;
  }
}
