import React, { Component } from "react";
import { ReactComponent as Logo } from "./ball-triangle.svg";
import styles from "./Loading.module.scss";

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var { fullscreen } = this.props;
    return (
      <div className={styles[fullscreen ? "Loading" : ""]}>
        <Logo className={styles["Logo"]} />
      </div>
    );
  }
}

export default Loading;
