import axios from "axios";

export default class AuthProvider {
  //
  constructor(id, url, headers) {
    this._id = id;
    this._url = url;
    this._headers = headers || {};

    this._http = this._initHttp();
  }

  _headers;
  _http;
  _id;
  _url;

  // Public.

  get id() {
    return this._id;
  }

  authenticate = (token, callback) => this._authenticate({}); // Override.

  // Protected.

  _authenticate = (data) => this._http.post(this._url, data, this._headers);

  // Private.

  _initHttp = () => {
    const http = axios.create({
      withCredentials: false,
      headers: this._headers,
    });

    http.interceptors.request.use(
      (request) => {
        // console.log(`${this._id} Request: `, request);
        return request;
      },
      (error) => console.log(error)
    );

    http.interceptors.response.use(
      (response) => {
        // console.log(`${this._id} Response: `, response);
        return response;
      },
      (error) => console.log(error)
    );

    return http;
  };
}
