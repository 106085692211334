import React, { Component } from "react";
import styles from "./Header.module.scss";
import menuImg from "../../assets/icon_bars_light.svg";
import { ReactComponent as Share } from "../../assets/share.svg";
import logoImg from "../../assets/YST-LOGO_MASTER_BLUE_DARK_GREY_32.png";
import DOMPurify from "dompurify";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../../Context/ThemeContext";
import { isMobile } from "react-device-detect";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
    };
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  _renderContent = (item) => {
    const { width } = this.state;

    return (
      <>
        {" "}
        {item.url !== undefined ? (
          <a
            href={item.url}
            className={styles["link-text-custom"]}
            style={{
              color:
                this.props.organisation?.theme?.header?.textColour !== undefined
                  ? this.props.organisation?.theme?.header?.textColour
                  : "#484848",
              "--header--hover-color":
                this.props.organisation?.theme?.header?.textHoverColour !==
                undefined
                  ? this.props.organisation?.theme?.header?.textHoverColour
                  : "#008489",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.text, {
                  ADD_ATTR: ["target"],
                  // sanitize: true, TODO: Re-implement this!
                }),
              }}
            />
          </a>
        ) : (
          <span className={styles["header-text"]}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.text, {
                  ADD_ATTR: ["target"],
                  // sanitize: true, TODO: Re-implement this!
                }),
              }}
            />
          </span>
        )}
      </>
    );
  };

  render() {
    const desktopHeader = this.props.organisation
      ? this.props.organisation?.desktopHeader
      : null;
    const { showMenu } = this.props;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div
            className={styles["Header"]}
            style={{
              color: isMobile
                ? "#484848"
                : theme.theme?.header?.textColour !== undefined
                ? theme.theme?.header?.textColour
                : "#484848",
              background: isMobile
                ? "#ffffff"
                : theme.theme?.header?.backgroundColour !== undefined
                ? theme.theme?.header?.backgroundColour
                : "#ffffff",
              "--hover-color":
                theme.theme?.header?.textHoverColour !== undefined
                  ? theme.theme?.header?.textHoverColour
                  : "#008489",
            }}
          >
            {isMobile ? (
              <div className={styles["header-outer-container-mobile"]}>
                <div className={styles["header-inner-container"]}>
                  <div
                    className={styles["header-inner-left"]}
                    style={{
                      width: "80px ",
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                        paddingLeft: "10px",
                      }}
                      className={styles["menu-button-container"]}
                      onClick={showMenu}
                    >
                      <img
                        src={menuImg}
                        className={styles["menu-button"]}
                        alt="Menu icon opens travel profile"
                      />{" "}
                    </div>
                  </div>
                  <div className={styles["header-inner-center"]}>
                    <img
                      height={33}
                      width={33}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      src={this.props.organisation?.desktopHeader?.imageUrl}
                      alt="new"
                    />
                  </div>

                  <div
                    className={styles["header-inner-right"]}
                    style={{
                      width: "80px",
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              <div className={styles["header-outer-container"]} v>
                <div className={styles["header-inner-container"]}>
                  <div className={styles["header-inner-left"]}>
                    {desktopHeader !== undefined &&
                      desktopHeader.items[0] !== undefined &&
                      this._renderContent(desktopHeader.items[0])}
                  </div>
                  <div className={styles["header-inner-center"]}>
                    {desktopHeader !== undefined &&
                      desktopHeader.items[1] !== undefined &&
                      this._renderContent(desktopHeader.items[1])}
                  </div>
                  <div className={styles["header-inner-right"]}>{""}</div>
                </div>
              </div>
            )}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Header;
