import { useState, useRef, useCallback, useMemo } from "react";
import ClusterMarker from "../MapMarkers/ClusterMarker";
import Cluster from "@urbica/react-map-gl-cluster";
import ClusterContext from "./ClusterContext";

//Clustering has to be done without any wrappers, as the cluster component directly accesses its children
//This component renders all the clustering markers
const ClusterHandler = ({ children, onClickCluster }) => {
  const [markerClusters, setMarkerClusters] = useState([]);
  const clusterRef = useRef();

  //Uses useCallback and useMemo to prevent unnecessary rerenders when the registered markers change
  const unRegisterMarkers = useCallback((removeMarkers) => {
    setMarkerClusters((currentItems) => {
      //Filter out any markers passed into the call
      return currentItems.filter(
        (registeredMarkers) =>
          !removeMarkers.some(
            (removeMarker) => removeMarker === registeredMarkers
          )
      );
    });
  }, []);

  const registerMarkers = useCallback((markers) => {
    setMarkerClusters((currentItems) => {
      //Register only markers that have not already been registered
      return [
        ...currentItems,
        ...markers.filter(
          (newMarker) =>
            !currentItems.some((currentMarker) => currentMarker === newMarker)
        ),
      ];
    });
  }, []);

  const registrationHandler = useMemo(
    () => ({ registerMarkers, unRegisterMarkers }),
    [registerMarkers, unRegisterMarkers]
  );

  function onClick(clusterClickEvent) {
    var supercluster = clusterRef.current.getCluster();
    const { clusterId, longitude, latitude } = clusterClickEvent;
    const zoom = supercluster.getClusterExpansionZoom(clusterId);
    onClickCluster({ zoom, longitude, latitude });
  }

  return (
    <ClusterContext.Provider value={registrationHandler}>
      {markerClusters?.[0] && (
        <Cluster
          ref={clusterRef}
          radius={40}
          extent={512}
          nodeSize={64}
          component={(cluster) => (
            <ClusterMarker onClick={onClick} {...cluster} />
          )}
        >
          {markerClusters}
        </Cluster>
      )}
      {children}
    </ClusterContext.Provider>
  );
};

export default ClusterHandler;
