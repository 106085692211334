import logger from "../logService";

const getItem = (key: string, parseFunction: any | null) => {
  if (typeof key !== "string") return null;

  let value: any;
  try {
    value = localStorage.getItem(key);
  } catch (error) {
    return null;
  }

  if (typeof parseFunction !== "function") return value;

  try {
    return parseFunction(value);
  } catch (error) {
    return value;
  }
};

const removeItem = (key: string) => {
  // if (typeof key !== "string") return null;

  try {
    localStorage.removeItem(key);
  } catch (error) {}
};

const setItem = (key: string, value: any) => {
  if (value == null) {
    removeItem(key);
    return;
  }

  const valueType = typeof value;

  // if (typeof key !== "string") return;
  if (valueType === "undefined" || valueType === "symbol") return;

  const storeValue = valueType === "string" ? value : JSON.stringify(value);

  try {
    localStorage.setItem(key, storeValue);
  } catch (error) {
    logger.error(error);
  }
};

const localDataService = {
  getItem,
  removeItem,
  setItem,
};

export default localDataService;
