import React, { Component } from "react";
import DOMPurify from "dompurify";
import Modal from "../Modal/Modal";

class DisruptionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { disruptionsMessages, close } = this.props;
    return (
      <Modal
        title={"Disruptions"}
        shouldOverflow={true}
        close={close}
        finish={() => {}}
      >
        {disruptionsMessages &&
          disruptionsMessages.map((message, index) => (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(message, {
                    ADD_ATTR: ["target"],
                    // sanitize: true, TODO: Re-implement this!
                  }),
                }}
              />
              {index < disruptionsMessages.length - 1 ? (
                <>
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
            </>
          ))}
      </Modal>
    );
  }
}

export default DisruptionsModal;
