import RestService from "./restService";
import authService from "../authService";
import { BASE_URL } from "../../domain/constants/api";
import { RequestAssistanceCommandDto } from "../../domain/dtos/";

function TravelForecastsRestService(apiToken: string | null = null) {
  apiToken = apiToken ?? authService.accessToken;

  const http = RestService(BASE_URL, "travelforecast/", apiToken);

  const requestAssistance = (dto: RequestAssistanceCommandDto) =>
    http.put(`travelforecast/${dto.id}/assistance`, dto);

  const getAssistanceRequest = (id: string) =>
    http.get(`travelforecast/${id}/assistance`);

  return {
    ...http,
    requestAssistance,
    getAssistanceRequest,
  };
}

export default TravelForecastsRestService;
