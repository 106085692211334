import React, { Component } from "react";
import styles from "./Profile.module.scss";
import EditProfile from "../EditProfile";
import NominatedAssistance from "../NominatedAssistance/NominatedAssistance";
import { ReactComponent as Close } from "../../assets/round-close-24px.svg";
import TravelPlanCollection from "../../TravelPlanCollection";
import userLocalDataService from "./../../../services/data/userLocalDataService";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { defaultFilters } from "../../../domain/constants/filters";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../../Context/ThemeContext";

library.add(fas, far, fad);

class Profile extends Component {
  constructor(props) {
    super(props);
    const apimUser = userLocalDataService.getAPIMUser();
    this.state = {
      filterPastTravelPlans: false,
      editProfile: false,
      usr: apimUser.firstName,
      getFilter: {},
      profilefilter: null,
      previousTravelPlans: null,
      nominatePage: false,
      ...defaultFilters,
    };
  }

  componentDidMount = () => {
    //Verify is request is targeted directly at viewing "Edit Profile"
    if (this.props.editProfile === true) {
      this.handleShowEditProfile();
      this.setState({ editProfile: this.props.editProfile });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.previousTravelPlans !== null) {
      this.setState({
        previousTravelPlans: nextProps.previousTravelPlans,
      });
    }
  };

  close = () => {
    this.props.toggleProfile();
  };

  finish = () => {
    this.props.finish();
  };

  filterPastTravelPlans = (filterPast) => {
    this.props.travelPlans(filterPast);
    this.setState({
      filterPastTravelPlans: filterPast,
    });
  };

  handleSaveUpdates = () => {
    var filters;
    this.setState({
      editProfile: true,
    });
    filters = {
      filterAdults: this.state.filterAdults,
      filterChildren: this.state.filterChildren,
      filterWheelchair: this.state.filterWheelchair,
      filterAccess: this.state.filterAccess,
      filterLuggage: this.state.filterLuggage,
      filterGuideDog: this.state.filterGuideDog,
      filterBicycle: this.state.filterBicycle,
      filterRemainingRange: this.state.filterRemainingRange,
      filterMaximumRange: this.state.filterMaximumRange,
      filterChargingTime: this.state.filterChargingTime,
      filterChademo: this.state.filterChademo,
      filterComboCCS: this.state.filterComboCCS,
      filterRapidAC43: this.state.filterRapidAC43,
      filterBus: this.state.filterBus,
      filterTram: this.state.filterTram,
      filterTrain: this.state.filterTrain,
    };
    this.close();
    this.props.saveprofilefilter(filters);
  };

  handleShowEditProfile = () => {
    this.setState({
      editProfile: true,
      filterAdults: this.props.profilefilters.filterAdults,
      filterChildren: this.props.profilefilters.filterChildren,
      filterWheelchair: this.props.profilefilters.filterWheelchair,
      filterAccess: this.state.filterAccess,
      filterLuggage: this.props.profilefilters.filterLuggage,
      filterGuideDog: this.props.profilefilters.filterGuideDog,
      filterBicycle: this.props.profilefilters.filterBicycle,
      filterRemainingRange: this.props.profilefilters.filterRemainingRange,
      filterMaximumRange: this.props.profilefilters.filterMaximumRange,
      filterChargingTime: this.props.profilefilters.filterChargingTime,
      filterChademo: this.props.profilefilters.filterChademo,
      filterComboCCS: this.props.profilefilters.filterComboCCS,
      filterRapidAC43: this.props.profilefilters.filterRapidAC43,
      filterBus: this.props.profilefilters.filterBus,
      filterTram: this.props.profilefilters.filterTram,
      filterTrain: this.props.profilefilters.filterTrain,
    });
  };

  handleWheelchairFilter = () => {
    const currentFilter = this.state.filterWheelchair;
    this.setState({ filterWheelchair: !currentFilter });
  };

  handleAccessFilter = () => {
    const currentFilter = this.state.filterAccess;
    this.setState({ filterAccess: !currentFilter });
    this.props.toggleRequestAssistance(null);
  };

  handleLuggageFilter = () => {
    const currentFilter = this.state.filterLuggage;
    this.setState({ filterLuggage: !currentFilter });
  };

  handleChademoFilter = () => {
    const currentFilter = this.state.filterChademo;
    this.setState({ filterChademo: !currentFilter });
  };

  handleComboCCSFilter = () => {
    const currentFilter = this.state.filterComboCCS;
    this.setState({ filterComboCCS: !currentFilter });
  };

  handleRapidAC43Filter = () => {
    const currentFilter = this.state.filterRapidAC43;
    this.setState({ filterRapidAC43: !currentFilter });
  };

  handlefilterBus = () => {
    const currentFilter = this.state.filterBus;
    this.setState({ filterBus: !currentFilter });
  };

  handlefilterTram = () => {
    const currentFilter = this.state.filterTram;
    this.setState({ filterTram: !currentFilter });
  };

  handlefilterTrain = () => {
    const currentFilter = this.state.filterTrain;
    this.setState({ filterTrain: !currentFilter });
  };

  handleGuideDogFilter = () => {
    const currentFilter = this.state.filterGuideDog;
    this.setState({ filterGuideDog: !currentFilter });
  };

  handleBicycleFilter = () => {
    const currentFilter = this.state.filterBicycle;
    this.setState({ filterBicycle: !currentFilter });
  };

  handleFilterQuantity = (stateField, operator) => {
    if (stateField === "filterChildren") {
      if (operator === "minus" && this.state.filterChildren > 0) {
        this.setState((prevState) => {
          return { filterChildren: prevState.filterChildren - 1 };
        });
      } else if (operator === "plus" && this.state.filterChildren < 100) {
        this.setState((prevState) => {
          return { filterChildren: prevState.filterChildren + 1 };
        });
      }
    } else if (stateField === "filterAdult") {
      if (operator === "minus" && this.state.filterAdults > 0) {
        this.setState((prevState) => {
          return { filterAdults: prevState.filterAdults - 1 };
        });
      } else if (operator === "plus" && this.state.filterAdults < 100) {
        this.setState((prevState) => {
          return { filterAdults: prevState.filterAdults + 1 };
        });
      }
    } else if (stateField === "filterChargingTime") {
      if (operator === "minus" && this.state.filterChargingTime > 0) {
        this.setState((prevState) => {
          return { filterChargingTime: prevState.filterChargingTime - 1 };
        });
      } else if (operator === "plus") {
        this.setState((prevState) => {
          return { filterChargingTime: prevState.filterChargingTime + 1 };
        });
      }
    } else if (stateField === "filterRemainingRange") {
      if (operator === "minus" && this.state.filterRemainingRange > 0) {
        this.setState((prevState) => {
          return { filterRemainingRange: prevState.filterRemainingRange - 1 };
        });
      } else if (operator === "plus") {
        this.setState((prevState) => {
          return { filterRemainingRange: prevState.filterRemainingRange + 1 };
        });
      }
    } else if (stateField === "filterMaximumRange") {
      if (operator === "minus" && this.state.filterMaximumRange > 0) {
        this.setState((prevState) => {
          return { filterMaximumRange: prevState.filterMaximumRange - 1 };
        });
      } else if (operator === "plus") {
        this.setState((prevState) => {
          return { filterMaximumRange: prevState.filterMaximumRange + 1 };
        });
      }
    }
  };

  isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  logout = () => {
    this.props.logout();
  };

  handleSaveUpdates = (filters) => {
    this.props.saveprofilefilter(filters);
  };

  handleNominatedAssistancePage = (nominatePage) => {
    this.setState({ nominatePage: nominatePage });
  };

  handleNominateToggle = () => {
    this.setState({
      nominatePage: !this.state.nominatePage,
      filterAccess: true,
    });
  };

  render() {
    const { editProfile, nominatePage } = this.state;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={styles["Modal"]}>
            <div className={styles["content"]} role="dialog">
              <div className={styles["header"]}>
                <div className={styles["button-container"]}>
                  <button
                    type="button"
                    className={styles["button"]}
                    onClick={this.close}
                    onKeyPress={this.close}
                  >
                    <Close className={styles["Close"]} />
                  </button>
                </div>

                <div className={styles["title-container"]}>
                  <div className={styles["title"]}>
                    <span>
                      <FormattedMessage id="myTravelProfile" />
                    </span>
                  </div>
                </div>

                <div className={styles["action-container"]}>
                  <div className={styles["action"]} />
                </div>
              </div>
              <div className={styles["main"]}>
                {/* Conditional Block */}
                {editProfile ? (
                  <>
                    {nominatePage ? (
                      <NominatedAssistance
                        userEmail={this.props.userEmail}
                        userId={this.props.userId}
                        apiToken={this.props.token}
                        toggleNominatePage={this.handleNominateToggle}
                        assistanceSettings={this.props.assistanceSettings}
                        createUserProfile={this.props.createUserProfile}
                        profileId={this.props.profileId}
                        defaultFrom={this.props.defaultFrom}
                        defaultTo={this.props.defaultTo}
                      />
                    ) : (
                      <EditProfile
                        toggleEditProfile={this.props.toggleEditProfile}
                        auth={this.props.auth}
                        profilefilters={this.props.profilefilters}
                        close={this.close}
                        saveProfileFilters={this.handleSaveUpdates}
                        editProfile={this.state.editProfile}
                        nominatedAssistancePage={
                          this.handleNominatedAssistancePage
                        }
                        assistanceSettings={this.props.assistanceSettings}
                        handleChademoFilter={this.handleChademoFilter}
                        handleComboCCSilter={this.handleComboCCSFilter}
                        handleRapidAC43Filter={this.handleRapidAC43Filter}
                        handlefilterBus={this.handlefilterBus}
                        handlefilterTram={this.handlefilterTram}
                        handlefilterTrain={this.handlefilterTrain}
                      />
                    )}
                  </>
                ) : (
                  <div className={styles["copy-button-container"]}>
                    <div className={styles["edit-profile-button-container"]}>
                      <button
                        type="button"
                        className={styles["edit-profile-button"]}
                        onClick={() => this.handleShowEditProfile()}
                      >
                        <span
                          style={{ color: theme.theme.primaryColour }}
                          className={styles["edit-profile-button-span"]}
                        >
                          <FormattedMessage id="editProfile" />
                        </span>
                      </button>
                      {this.props.auth.isAuthenticated() === false ? (
                        this.props.thirdPartyAuthenticated === true ? (
                          ""
                        ) : (
                          <p className={styles["auth-required-text"]}>
                            <FormattedMessage id="loginToViewTrips" />
                          </p>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={styles["filter-button-container"]}>
                      <button
                        type="button"
                        style={{
                          background: theme.theme.primaryColour,
                        }}
                        className={`${styles["filter-button"]} ${
                          !this.state.filterPastTravelPlans
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                        onClick={() => this.filterPastTravelPlans(false)}
                        onKeyPress={() => this.filterPastTravelPlans(false)}
                      >
                        <span
                          style={{
                            color: this.state.filterPastTravelPlans
                              ? theme.theme.primaryColour
                              : "white",
                          }}
                          className={styles["filter-button-span"]}
                        >
                          <FormattedMessage id="plannedTrips" />
                        </span>
                      </button>
                      <button
                        type="button"
                        style={{
                          background: theme.theme.primaryColour,
                        }}
                        className={`${styles["filter-button2"]} ${
                          this.state.filterPastTravelPlans
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                        onClick={() => this.filterPastTravelPlans(true)}
                        onKeyPress={() => this.filterPastTravelPlans(true)}
                      >
                        <span
                          style={{
                            color: !this.state.filterPastTravelPlans
                              ? theme.theme.primaryColour
                              : "white",
                          }}
                          className={styles["filter-button-span2"]}
                        >
                          <FormattedMessage id="pastTrips" />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
                {!editProfile ? (
                  <TravelPlanCollection
                    previousTravelPlans={this.state.previousTravelPlans}
                    pastTravelPlans={this.props.pastTravelPlans}
                    filterPast={this.state.filterPastTravelPlans}
                    getPreviousTravelPlan={this.props.getPreviousTravelPlan}
                    logout={this.props.logout}
                    destinationGroup={this.props.destinationGroup}
                    toggleRequestAssistance={this.props.toggleRequestAssistance}
                    assistanceSettings={this.props.assistanceSettings}
                    destinationGroupAssistanceSettings={
                      this.props.destinationGroupAssistanceSettings
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Profile;
