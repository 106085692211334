import React, { Component } from "react";
import styles from "./NoPlans.module.scss";

class NoPlans extends Component {
  render() {
    return (
      <li className={`${styles["Journey"]} ${styles["highlight"]}`}>
        <div className={styles["journey-padding"]}>
          <div className={styles["journey-sub"]}>
            <div className={styles["journey-title-outer-container"]}>
              <div className={styles["journey-title-inner-container"]}>
                <div className={styles["journey-title"]}>
                  <span className={styles["travelPlan-title"]}>
                    {this.props.NoPlanMessage}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default NoPlans;
