import React, { useState, useEffect } from "react";
import styles from "./LoadingMessages.module.scss";

const dots = ["", ".", "..", "...", "...."];

function LoadingMessages({ messages, messageDuration, dotsCyclePerDuration }) {
  //
  const [messageIndex, setMessageIndex] = useState(0);
  const [messageText, setMessageText] = useState(messages[messageIndex]);

  const loadingInterval = Math.round(
    messageDuration / (dots.length * dotsCyclePerDuration)
  );

  useEffect(() => {
    //
    let dotsIndex = 0;
    let dotsCycle = 0;

    const timer = setInterval(() => {
      setMessageText(messages[messageIndex] + dots[dotsIndex]);
      dotsIndex += 1;

      if (dotsIndex === dots.length) {
        dotsIndex = 0;
        dotsCycle += 1;

        if (dotsCycle === dotsCyclePerDuration) {
          const index =
            messageIndex + 1 < messages.length ? messageIndex + 1 : 0;
          setMessageIndex(index);
        }
      }
    }, loadingInterval);

    return () => clearInterval(timer);
  }, [messageIndex, dotsCyclePerDuration, loadingInterval, messages]);

  return (
    <div className={styles["Loading"]}>
      <div className={styles["LoadingTextContainer"]}>
        <p className={styles["LoadingText"]}>{messageText}</p>
      </div>
    </div>
  );
}

export default LoadingMessages;
