import authLocalData from "../services/data/authLocalDataService";
import authLocalDataService from "./../services/data/authLocalDataService";
import jwt_decode from "jwt-decode";
import safeLocalStorageService from "./../services/useSafeLocalStorage";


export default class Auth {
  constructor(history, pathname) {
    this.history = history;
    this.userProfile = null;
    this.pathname = pathname;
    // this.auth0 = new auth0.WebAuth({
    //   domain: process.env.REACT_APP_AUTH_DOMAIN,
    //   clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
    //   redirectUri: process.env.REACT_APP_CALLBACK_URL,
    //   //redirectUri: "http://localhost:3000/callback",
    //   responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
    //   overrides: {
    //     __token_issuer: process.env.REACT_APP_AUTH_TOKEN_ISSUER
    //   },
    //   scope: "openid profile email write:user",
    //   responseMode: "form_post"
    // });
  }

  login = () => {
    try {
      localStorage.setItem("base_Url", window.location.href);
      localStorage.setItem("loggedIn", false);
    } catch (err) {
      console.log(err.message);
    }
    var lang = localStorage.getItem("lang");

    var localStorageCallbackURL = safeLocalStorageService.getItem("callbackURL");
    let domainCallbackURL = process.env.REACT_APP_CALLBACK_URL;

    if (localStorageCallbackURL !== null && localStorageCallbackURL !== undefined
      && localStorageCallbackURL !== 'undefined' && localStorageCallbackURL !== "") {

      domainCallbackURL = localStorageCallbackURL;
    }
    // this.auth0.authorize();
    var redirectUrl =
      process.env.REACT_APP_AUTH_DOMAIN +
      process.env.REACT_APP_AUTH_CLIENT_ID +
      "&response_type=" +
      process.env.REACT_APP_AUTH_RESPONSE_TYPE +
      "&redirect_uri=" +
      domainCallbackURL +
      "&lang=" +
      lang;
    window.location.href = redirectUrl;
  };

  // Disabled
  loginThenSubmitForm = (
    user,
    travelForecast,
    journeyOptionId,
    journeyPlanId,
    destinationName
  ) => {
    localStorage.setItem("base_Url", window.location.href);
    authLocalDataService.setAPIMUser(user);
    localStorage.setItem("travelForecast", JSON.stringify(travelForecast));
    localStorage.setItem("journeyOptionId", journeyOptionId);
    localStorage.setItem("journeyPlanId", journeyPlanId);
    localStorage.setItem("destinationName", destinationName);
    var redirectUrl =
      process.env.REACT_APP_AUTH_DOMAIN +
      process.env.REACT_APP_AUTH_CLIENT_ID +
      "&response_type=" +
      process.env.REACT_APP_AUTH_RESPONSE_TYPE +
      "&redirect_uri=" +
      process.env.REACT_APP_CALLBACK_URL;
    window.location.href = redirectUrl; // this.auth0.authorize();
  };

  handleAuthentication = (auth) => {
    // this.auth0.parseHash( ( err, hash ) => {
    //   if ( hash && hash.accessToken && hash.idToken ) {
    //     // Success.
    //     this._handleAuthenticationSuccess( hash );
    //     window.location.href = this.pathname === "/callback" ? localStorage.getItem("base_Url") : this.pathname;
    //   } else if (err) {
    //     // Failure.
    //     this.history.push("/");
    //     alert(`Error: ${err.error}. Check the console for further details.`);
    //     console.log(err);
    //   }
    // });

    try {
      const authObject = this.parseAuthHash(auth);
      this._handleAuthenticationSuccess(authObject);
      window.location.href =
        this.pathname === "/callback"
          ? localStorage.getItem("base_Url")
          : this.pathname;
    } catch (error) {
      this.history.push("/");
      alert(`Error: ${error.error}. Check the console for further details.`);
      console.log(error);
    }
  };

  parseAuthHash = (auth) => {
    const tempAuthString = auth.location.hash.substring(1);
    let tempAuthArray = tempAuthString.split("&");
    var authResult = {};
    tempAuthArray.forEach(function (tempAuthArray) {
      var newValue = tempAuthArray.split("=");
      authResult[newValue[0]] = newValue[1];
    });
    return authResult;
  };

  _handleAuthenticationSuccess = (authResult) => {
    const idToken = authResult.id_token;
    const accessToken = authResult.access_token;
    const expiresAt =
      parseInt(authResult.expires_in) * 1000 + new Date().getTime();
    var decoded = jwt_decode(authResult.id_token);
    const userData = decoded;

    // cookieService.setAuthCookies(idToken, accessToken);

    authLocalData.setAuth({
      accessToken,
      idToken,
      expiresAt
    });
    authLocalData.setAPIMUser({
      identityEmail: userData.email,
      firstName: userData.given_name,
      lastName: userData.family_name,
    });
  };

  isAuthenticated() {
    return !!authLocalData.getIdToken();
  }
  //TO DO: Cookies set here should be non-persistent. Currently this is a vulnerability on closing the application sessions are not cleared.
  logout = () => {
    authLocalData.clear();

    this.userProfile = null;

    var localStorageLogoutURL = safeLocalStorageService.getItem("logoutRedirectURL");
    let logoutRedirectURL = process.env.REACT_APP_LOG_OUT_REDIRECT_URL;

    if (localStorageLogoutURL !== null && localStorageLogoutURL !== undefined
      && localStorageLogoutURL !== 'undefined' && localStorageLogoutURL !== "") {

      logoutRedirectURL = localStorageLogoutURL;
    }

    window.location.href = process.env.REACT_APP_LOG_OUT_URL + logoutRedirectURL;
  };

  getAccessToken = () => {
    const accessToken = authLocalData.getAccessToken();
    if (!accessToken) {
      throw new Error("No access token found.");
    }
    return accessToken;
  };

  getProfile = (cb) => {
    if (this.userProfile) return cb(this.userProfile);
    this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
      if (profile) this.userProfile = profile;
      cb(profile, err);
    });
  };
}
