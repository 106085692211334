import React from "react";
import { Popup } from "@urbica/react-map-gl";
import moment from "moment";

import styles from "./MapMarkerTrafficPopup.module.scss";

const MapMarkerTrafficPopup = (props) => {
  /* Setup */
  const { activeTrafficObject } = props;
  const [showPopup, togglePopup] = React.useState(false);
  const [forceShowPopup, setForceShowPopup] = React.useState({
    ...props.forceShowPopup,
  });

  const latitude =
    activeTrafficObject.groupOfLocations.tpegPointLocation.point
      .pointCoordinates.latitude;
  const longitude =
    activeTrafficObject.groupOfLocations.tpegPointLocation.point
      .pointCoordinates.longitude;
  const endTime = new Date(
    activeTrafficObject.validity.validityTimeSpecification.overallEndTime
  );
  const startTime = new Date(
    activeTrafficObject.validity.validityTimeSpecification.overallStartTime
  );

  /* Methods */
  React.useEffect(() => {
    togglePopup(true);

    console.log(activeTrafficObject);
    // Runs after the first render() lifecycle
  }, [activeTrafficObject]);

  React.useEffect(() => {
    if (forceShowPopup) {
      togglePopup(true);
    }
  }, [forceShowPopup]);

  React.useEffect(() => {
    setForceShowPopup(props.forceShowPopup);
  }, [props.forceShowPopup]);

  return (
    <div>
      {showPopup && latitude && longitude && (
        <Popup
          anchor="top"
          longitude={longitude}
          latitude={latitude}
          closeButton={true}
          closeOnClick={false}
          onClose={() => {
            props.updateCloseCallBack();
          }}
        >
          <div className={styles["pop-up-inner"]}>
            <div className={styles["header"]}>
              {" "}
              {activeTrafficObject.cause.causeDescription.values.value[0].text}
            </div>
            {activeTrafficObject.validity &&
              activeTrafficObject.validity.validityTimeSpecification && (
                <div className={styles["h2"]}>
                  Date: {moment.parseZone(startTime).format("Do MMMM  H:mm")} -{" "}
                  {moment.parseZone(endTime).format("Do MMMM  H:mm")}
                </div>
              )}
            <div className={styles["h2"]}>
              Description:{" "}
              {
                activeTrafficObject.generalPublicComment.comment.values.value[0]
                  .text
              }
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default MapMarkerTrafficPopup;
