import React, { Component } from "react";
import styles from "./Footer.module.scss";
import { ReactComponent as Share } from "../../assets/share.svg";
import logoImg from "../../assets/YST-LOGO_MASTER_BLUE_DARK_GREY_32.png";
import ReactHtmlParser from "html-react-parser";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../../Context/ThemeContext";
import { isMobile } from "react-device-detect";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
    };
    window.addEventListener("resize", this.updateDimensions);
  }

  toggleShare = () => {
    this.props.toggleSharepage(false);
  };
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  _renderContent = () => {
    const mobileFooter = this.props.organisation
      ? this.props.organisation.mobileFooter
      : null;
    const desktopFooter = this.props.organisation
      ? this.props.organisation.desktopFooter
      : null;
    return (
      <>
        {" "}
        {isMobile &&
          (!mobileFooter ? (
            <div>
              {this.props.organisation?.theme?.footer?.hideDefaultLogo !==
                undefined &&
              this.props.organisation?.theme?.footer?.hideDefaultLogo ? (
                ""
              ) : (
                <>
                  <img
                    src={logoImg}
                    alt="logo"
                    className={styles["footer-logo"]}
                  />{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://yousmartthing.com/uk"
                    className={styles["footer-a"]}
                  >
                    <strong className={styles["link-text"]}>
                      You. Smart. Thing.
                    </strong>
                  </a>
                </>
              )}
            </div>
          ) : (
            <div>
              <span>
                {mobileFooter.heading && ReactHtmlParser(mobileFooter.heading)}
              </span>
              {mobileFooter.quickLink.map((link, index) => (
                <span>
                  {this.props.organisation?.theme?.footer?.divider !== undefined
                    ? ReactHtmlParser(
                        this.props.organisation?.theme?.footer?.divider
                      )
                    : `  |  `}
                  <a
                    href={link.url}
                    className={styles["link-text-custom"]}
                    style={{
                      color:
                        this.props.organisation?.theme?.footer?.textColour !==
                        undefined
                          ? this.props.organisation?.theme?.footer?.textColour
                          : "#484848",
                      "--hover-color":
                        this.props.organisation?.theme?.footer
                          ?.textHoverColour !== undefined
                          ? this.props.organisation?.theme?.footer
                              ?.textHoverColour
                          : "#008489",
                      fontSize:
                        this.props.organisation?.theme?.footer
                          ?.mobileFontSize !== undefined
                          ? this.props.organisation?.theme?.footer
                              ?.mobileFontSize + "px"
                          : "14px",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </a>
                </span>
              ))}
            </div>
          ))}
        {!isMobile &&
          (!desktopFooter ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <span className={styles["footer-inner-left-text"]}>
                <FormattedMessage id="sustainability" />
              </span>
              <img
                src={logoImg}
                alt="logo"
                style={{ marginLeft: "3px" }}
                className={styles["footer-logo"]}
              />{" "}
              <a
                style={{ marginLeft: "3px" }}
                rel="noopener noreferrer"
                target="_blank"
                href="https://yousmartthing.com/uk"
                className={styles["footer-a"]}
              >
                <strong className={styles["link-text"]}>
                  You. Smart. Thing.
                </strong>
              </a>
              <span>
                &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                <a
                  className={styles["link-text"]}
                  href="https://yousmartthing.com/uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="menuAbout" />
                </a>
                &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                <a
                  className={styles["link-text"]}
                  href="https://yousmartthing.atlassian.net/servicedesk/customer/portals"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="menuHelp" />
                </a>
                &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                <a
                  className={styles["link-text"]}
                  href="https://yousmartthing.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="menuTerms" />
                </a>
              </span>
            </div>
          ) : (
            <div>
              <span>
                {desktopFooter.heading &&
                  ReactHtmlParser(desktopFooter.heading)}
              </span>
              {desktopFooter.quickLink.map((link, index) => (
                <span>
                  {this.props.organisation?.theme?.footer?.divider !== undefined
                    ? ReactHtmlParser(
                        this.props.organisation?.theme?.footer?.divider
                      )
                    : `  |  `}
                  <a
                    href={link.url}
                    className={styles["link-text-custom"]}
                    style={{
                      color:
                        this.props.organisation?.theme?.footer?.textColour !==
                        undefined
                          ? this.props.organisation?.theme?.footer?.textColour
                          : "#484848",
                      "--hover-color":
                        this.props.organisation?.theme?.footer
                          ?.textHoverColour !== undefined
                          ? this.props.organisation?.theme?.footer
                              ?.textHoverColour
                          : "#008489",
                      fontSize:
                        this.props.organisation?.theme?.footer
                          ?.desktopFontSize !== undefined
                          ? this.props.organisation?.theme?.footer
                              ?.desktopFontSize + "px"
                          : "15px",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </a>
                </span>
              ))}
            </div>
          ))}
      </>
    );
  };
  render() {
    var position = this.props.organisation?.theme?.footer?.position
      ? this.props.organisation?.theme?.footer?.position
      : "left";
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={styles["Footer"]}>
            <div
              className={styles["footer-outer-container"]}
              style={{
                background:
                  theme.theme?.footer?.backgroundColour !== undefined
                    ? theme.theme?.footer?.backgroundColour
                    : "#ffffff",
              }}
            >
              <div className={styles["footer-inner-container"]}>
                <div className={styles["footer-inner-left"]}>
                  {position === "left" ? this._renderContent() : ""}
                </div>

                <div className={styles["footer-inner-center"]}>
                  {position === "center" ? this._renderContent() : ""}
                </div>

                <div className={styles["footer-inner-right"]}>
                  {!theme?.hideClientElements?.includes("hideShareButton") && (
                    <div
                      style={{
                        display: "flex",
                        float: "right",
                      }}
                      onClick={this.toggleShare}
                    >
                      <strong className={styles["footer-inner-right-span"]}>
                        {" "}
                        <FormattedMessage id="share" />
                      </strong>
                      <Share className={styles["footer-logo"]} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Footer;
