export const destinationError = "Unable to load destinations right now...";
export const destinationGroupItemsError =
  "Unable to load destination group items right now...";
export const travelPlanError = "Unable to load travel plans right now...";
export const travelForecastError =
  "Unable to load travel forecast right now...";
export const journeyPlanError = "Unable to fetch journey plans right now...";
export const genericErrorMessage = "Please try again later";
export const genericErrorType = "Seems like there is a problem right now...";
export const mercatorError =
  "Can not load destinations right now, please change screen orientation";
