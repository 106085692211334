import React from "react";
import { ReactComponent as LocationOn } from "../../assets/round-location_filled-24px.svg";
//import {ReactComponent as Walk} from "../../assets/round-directions_walk-24px.svg";
import { ReactComponent as Ferry } from "../../assets/round-directions_boat-24px.svg";
import { ReactComponent as Rail } from "../../assets/round-directions_railway-24px.svg";
import { ReactComponent as Bus } from "../../assets/round-directions_bus-24px.svg";
import { ReactComponent as Tram } from "../../assets/round-tram-24px.svg";
import { ReactComponent as Underground } from "../../assets/round-directions_subway-24px.svg";
import { ReactComponent as Drive } from "../../assets/round-directions_car-24px.svg";
// import {ReactComponent as Park} from "../../assets/round-local_parking-24px.svg";
// import {ReactComponent as EvStation} from "../../assets/round-ev_station-24px.svg";
// import {ReactComponent as Cycle} from "../../assets/Bicycle.svg";
// import {ReactComponent as CycleDock} from "../../assets/cycle_hire.svg";
// import {ReactComponent as Destination} from "../../assets/round-location_filled-24px.svg";
// import {ReactComponent as Plane} from "../../assets/plane-solid.svg";
import styles from "./VehicleDisplay.module.scss";

const VehicleDisplay = ({ vehicleType }) => {
  let Component;
  if (vehicleType === "Tram") {
    Component = <Tram className={styles["Tram"]} />;
  } else if (vehicleType === "Rail") {
    Component = <Rail className={styles["Rail"]} />;
  } else if (vehicleType === "Bus") {
    Component = <Bus className={styles["Bus"]} />;
  } else if (vehicleType === "Taxi") {
    Component = <Drive className={styles["Drive"]} />;
  } else if (vehicleType === "Ferry") {
    Component = <Ferry className={styles["Ferry"]} />;
  } else if (vehicleType === "Underground") {
    Component = <Underground className={styles["Underground"]} />;
  } else {
    Component = <LocationOn className={styles["LocationOn"]} />;
  }

  return <div>{Component}</div>;
};

export default VehicleDisplay;
