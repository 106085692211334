const setCookie = (name: string, value: any, days: number | null) => {
  var expires = "";
  // var samesite = `; SameSite=None; Secure;`;

  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ""}${expires}; path=/`;
  //document.cookie = `${name}=${value || ""}${expires}${samesite} path=/`;
};

const getCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

const deleteCookie = (name: string) => {
  if (!getCookie(name)) return;

  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

const setAuthCookies = (idToken: string, accessToken: string) => {
  setCookie("id_token", idToken, 1 / 2);
  setCookie("access_token", accessToken, 1 / 2);
};

const deleteAuthCookies = () => {
  deleteCookie("id_token");
  deleteCookie("access_token");
};

const cookieService = {
  getCookie,
  setCookie,
  deleteCookie,
  setAuthCookies,
  deleteAuthCookies,
};

export default cookieService;
