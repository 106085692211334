import React, { PureComponent } from "react";
// import { ReactComponent as CurrentLocationIcon } from "../../components/assets/YST.svg";
// import styles from "./CurrentLocation.module.scss";

export default class CurrentLocation extends PureComponent {
  render() {
    // return <CurrentLocationIcon className={styles["CurrentLocationIcon"]} />;
    return (
      <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" fill={this.props.colour} r="15">
          <animate
            attributeName="r"
            from="8"
            to="20"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    );
  }
}
