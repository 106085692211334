export const defaultFilters = {
  filterAdults: 1,
  filterChildren: 0,
  filterRemainingRange: 70,
  filterMaximumRange: 200,
  filterChargingTime: 45,
  filterChademo: true,
  filterComboCCS: true,
  filterRapidAC43: true,
  filterBus: true,
  filterTrain: true,
  filterTram: true,
  filterAccess: false,
  filterWheelchair: false,
  filterLuggage: false,
  filterGuideDog: false,
  filterBicycle: false,
};
