import http from "./httpService";
import { MAPTILER_PATH_WITH_TOKEN, MAPBOX_PATH } from "../domain/constants";

const validateMapPathAsync = async (path: string) => {
  let mapPath = path ? path : MAPTILER_PATH_WITH_TOKEN;
  try {
    await http.get(mapPath);
    return mapPath;
  } catch (error) {
    mapPath = MAPBOX_PATH;
    return mapPath;
  }
};

const validateMapPath = (path: string, callback: any) => {
  if (typeof path !== "string") return;
  const validate = async () => {
    const validPath = await validateMapPathAsync(path);
    if (typeof callback == "function") {
      callback(validPath);
    }
  };
  validate();
};

const mapService = {
  validateMapPath,
};

export default mapService;
