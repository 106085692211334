import React, { Component } from "react";
import moment from "moment";
import styles from "./JourneyCollection.module.scss";
import Journey from "../Journey";
import { ThemeContext } from "../../Context/ThemeContext";
import Loading from "../common/Loading";

class JourneyCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnJourneyLoading: false,
    };
  }

  static contextType = ThemeContext;

  componentDidMount = () => {
    var activeJourney = this.props.activeJourney;
    var isTravelPlan = this.props.showTravelPlan;
    var itineraryEnabled = this.props.itineraryEnabled;

    this.props.journeys.journeyOptions.forEach(function (journey, index) {
      if (index === activeJourney) {
        journey.highlight = true;
      } else {
        journey.highlight = false;
      }
      if (!isTravelPlan || itineraryEnabled) {
        journey.active = false;
        journey.show = true;
      }

      journey.order = index;
    });
  };

  componentDidUpdate = () => {
    if (
      this.state.returnJourneyLoading &&
      !!this.props.returnJourneys?.journeyOptionId
    ) {
      this.setLoading(false);
    }
  };

  setLoading = (isLoading) => {
    this.setState({ returnJourneyLoading: isLoading });
  };

  toggleOutwardReturn = (showReturn) => {
    if (showReturn && !this.props.returnJourneys?.journeyPlanId) {
      this.setLoading(true);
    }

    this.props.setShowReturn(showReturn);
    this.props.clearActiveTrackpoints();
    this.setInitialViewPort();
  };

  activateJourney = (order) => {
    if (order !== null) {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        if (journey.order === order) {
          journey.active = true;
        } else {
          journey.active = false;
        }
      });
    } else {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        journey.active = false;
      });
    }
  };

  highlightJourney = (order) => {
    if (order !== null) {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        if (journey.order === order) {
          journey.highlight = true;
        } else {
          journey.highlight = false;
        }
      });
    } else {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        journey.highlight = false;
      });
    }
  };

  highlightReturnJourney = (order) => {
    if (order !== null) {
      this.props.returnJourneys.journeyOptions.forEach(function (journey) {
        if (journey.order === order) {
          journey.highlight = true;
        } else {
          journey.highlight = false;
        }
      });
    } else {
      this.props.returnJourneys.journeyOptions.forEach(function (journey) {
        journey.highlight = false;
      });
    }
  };

  showJourney = (order) => {
    if (order !== null) {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        if (journey.order === order) {
          journey.show = true;
        } else {
          journey.show = false;
        }
      });
    } else {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        journey.show = true;
      });
    }
  };

  showReturnJourney = (order) => {
    if (order !== null) {
      this.props.returnJourneys.journeyOptions.forEach(function (journey) {
        if (journey.order === order) {
          journey.show = true;
        } else {
          journey.show = false;
        }
      });
    } else {
      this.props.returnJourneys.journeyOptions.forEach(function (journey) {
        journey.show = true;
      });
    }
  };

  setInitialViewPort = () => {
    this.props.setViewportToJourney(this.props.activeJourney);
  };

  toggleActiveJourney = (order) => {
    this.activateJourney(order);
    this.showJourney(order);
    if (order === null) {
      this.props.setViewportToJourney(this.props.activeJourney);
    }
    this.props.toggleActiveJourney(order);
  };

  toggleActiveReturnJourney = (order) => {
    this.activateJourney(order);
    this.showReturnJourney(order);
    if (order === null) {
      this.props.setViewportToJourney(this.props.activeReturnJourney);
    }
    this.props.toggleActiveReturnJourney(order);
  };

  toggleHighlightedJourney = (order) => {
    if (this.props.activeJourney !== order) {
      this.props.setViewportToJourney(order);
    }
    this.highlightJourney(order);
    this.props.toggleActiveJourney(order);
  };

  toggleHighlightedReturnJourney = (order) => {
    if (this.props.activeReturnJourney !== order) {
      this.props.setViewportToJourney(order);
    }
    this.highlightReturnJourney(order);
    this.props.toggleActiveReturnJourney(order);
  };

  handleLoginOperation = () => {
    this.props.handleLoginOperation();
  };

  handleLegUpdateClicked = (updatedLegs) => {
    this.props.onLegUpdateClicked(updatedLegs);
  };

  handleJourneyClicked = (activeJourneyLegs) => {
    this.props.onJourneyClicked(activeJourneyLegs);
  };

  handleReturnJourneyClicked = (activeReturnJourneyLegs) => {
    this.props.onReturnJourneyClicked(activeReturnJourneyLegs);
  };

  toggleSelection = () => {
    this.props.toggleSelection();
  };

  toggleRequestAssistance = (travelForecastId) => {
    this.props.toggleRequestAssistance(travelForecastId);
  };

  handleThirdPartyAction = (customData) => {
    this.props.onThirdPartyActionClicked(customData);
  };

  render() {
    const {
      journeys,
      returnJourneys,
      locationDropDownOpen,
      showReturn,
      departureTime,
      activeTimeTarget,
      activeJourney,
      activeReturnJourney,
      isReturn,
    } = this.props;

    const hasReturnJourneys = !!returnJourneys?.journeyOptionId;
    const { returnJourneyLoading } = this.state;

    const isOnlyShowingSingleJourney =
      journeys?.journeyOptions?.filter((journeyOption) => journeyOption.show)
        .length == 1;

    const isOnlyShowingSingleReturnJourney =
      returnJourneys?.journeyOptions?.filter(
        (journeyOption) => journeyOption.show
      ).length == 1;

    const returnTimeToUse = !hasReturnJourneys
      ? this.props.selectedReturnDateTime
        ? this.props.selectedReturnDateTime
        : this.props?.activeEvent?.endTime
      : returnJourneys.journeyOptions[activeReturnJourney].legs[0]
          .departureTime;

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div
            className={`${styles["JourneyCollection"]} ${
              styles[locationDropDownOpen ? "HideOnMobile" : ""]
            }`}
            style={{
              marginBottom: theme?.hideClientElements?.includes("hideFooter")
                ? "0px"
                : "40px",
            }}
          >
            <div className={styles["visually-hidden"]} aria-live="assertive">
              {" "}
              {journeys.journeyOptions.length}{" "}
              {journeys.journeyOptions.length > 1
                ? "routes are found"
                : "route is found"}
            </div>
            {theme.showReturnButton && isReturn ? (
              <div className={styles["outward-return-container"]}>
                <div
                  onClick={() => {
                    this.toggleOutwardReturn(false);
                  }}
                  className={styles["outward-return-inner-container"]}
                  style={{
                    paddingLeft: "16px",
                    background: !showReturn
                      ? activeJourney == 0
                        ? "white"
                        : isOnlyShowingSingleJourney
                        ? "white"
                        : "#f8f8f8"
                      : "#dddddd",
                    color: "#484848",
                    float: "left",
                  }}
                >
                  <span>Outward</span>
                  <br />
                  <span className={styles["outward-return-date"]}>
                    {moment
                      .parseZone(
                        activeTimeTarget === "departureTime"
                          ? departureTime
                          : journeys.journeyOptions[activeJourney].legs[0]
                              .departureTime
                      )
                      .format("H:mm") + " "}
                    {moment
                      .parseZone(
                        activeTimeTarget === "departureTime"
                          ? departureTime
                          : journeys.journeyOptions[activeJourney].legs[0]
                              .departureTime
                      )
                      .format(" on ddd D MMM") + " "}
                  </span>
                </div>
                <div
                  className={styles["outward-return-inner-container"]}
                  onClick={() => {
                    this.toggleOutwardReturn(true);
                  }}
                  style={{
                    paddingLeft: "16px",
                    background: showReturn
                      ? activeReturnJourney == 0 && !returnJourneyLoading
                        ? "white"
                        : isOnlyShowingSingleReturnJourney
                        ? "white"
                        : "#f8f8f8"
                      : "#dddddd",
                    color: "#484848",
                    float: "left",
                  }}
                >
                  <span
                    style={{
                      float: "left",
                    }}
                  >
                    Return
                  </span>
                  <br />
                  <span className={styles["outward-return-date"]}>
                    {moment.parseZone(returnTimeToUse).format("H:mm") + " "}
                    {moment.parseZone(returnTimeToUse).format(" on ddd D MMM") +
                      " "}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <ol className={styles["journey-list"]}>
              {!showReturn
                ? journeys.journeyOptions.map(
                    (journey, index) =>
                      (!journey.hasParentJourney ||
                        (journeys.journeyOptions.find(
                          (parentJourney) =>
                            parentJourney.id === journey.parentJourney
                        )?.subJourneysExpanded ??
                          false)) && (
                        <Journey
                          key={index}
                          journey={journey}
                          toggleActiveJourney={this.toggleActiveJourney}
                          toggleHighlightedJourney={
                            this.toggleHighlightedJourney
                          }
                          onJourneyLegClicked={this.props.onJourneyLegClicked}
                          onJourneyClicked={this.handleJourneyClicked}
                          onLegUpdateClicked={this.handleLegUpdateClicked}
                          activeJourney={this.props.activeJourney}
                          enableNavigation={this.props.enableNavigation}
                          thirdPartyData={this.props.thirdPartyData}
                          thirdPartyAlternative={journey.thirdPartyAlternative}
                          onThirdPartyActionClicked={
                            this.handleThirdPartyAction
                          }
                          additionalService={this.props.additionalService}
                          apiToken={this.props.apiToken}
                          toggleSelection={this.toggleSelection}
                          showTravelPlan={this.props.showTravelPlan}
                          travelForecast={this.props.travelForecast}
                          toggleRequestAssistance={this.toggleRequestAssistance}
                          setJourneysExpanded={this.props.setJourneysExpanded}
                          auth={this.props.auth}
                          showInfoPopup={this.props.showInfoPopup}
                          subJourneysExpanded={journey.subJourneysExpanded}
                          displayDisruptionsModal={
                            this.props.displayDisruptionsModal
                          }
                          toggleDisruptionsModal={
                            this.props.toggleDisruptionsModal
                          }
                          setInitialViewPort={this.setInitialViewPort}
                          terminateLegAnimation={
                            this.props.terminateLegAnimation
                          }
                          setTerminateLegAnimation={
                            this.props.setTerminateLegAnimation
                          }
                          toggleDirectionsModal={
                            this.props.toggleDirectionsModal
                          }
                          setSelectedLegIndex={this.props.setSelectedLegIndex}
                          setAlertsButton={this.props.setAlertsButton}
                          alertsButtonDisabled={this.props.alertsButtonDisabled}
                        />
                      )
                  )
                : ""}
              {returnJourneyLoading && showReturn ? (
                <>
                  <div style={{ marginTop: "55%" }}></div>
                  <Loading />
                </>
              ) : (
                ""
              )}
              {hasReturnJourneys && showReturn
                ? returnJourneys.journeyOptions.map(
                    (journey, index) =>
                      (!journey.hasParentJourney ||
                        (returnJourneys.journeyOptions.find(
                          (parentJourney) =>
                            parentJourney.id === journey.parentJourney
                        )?.subJourneysExpanded ??
                          false)) && (
                        <Journey
                          key={index}
                          journey={journey}
                          toggleActiveJourney={this.toggleActiveReturnJourney}
                          toggleHighlightedJourney={
                            this.toggleHighlightedReturnJourney
                          }
                          onJourneyLegClicked={this.props.onJourneyLegClicked}
                          onJourneyClicked={this.handleReturnJourneyClicked}
                          onLegUpdateClicked={this.handleLegUpdateClicked}
                          activeJourney={this.props.activeReturnJourney}
                          enableNavigation={this.props.enableNavigation}
                          thirdPartyData={this.props.thirdPartyData}
                          thirdPartyAlternative={journey.thirdPartyAlternative}
                          onThirdPartyActionClicked={
                            this.handleThirdPartyAction
                          }
                          apiToken={this.props.apiToken}
                          toggleSelection={this.toggleSelection}
                          showTravelPlan={this.props.showTravelPlan}
                          travelForecast={this.props.travelForecast}
                          toggleRequestAssistance={this.toggleRequestAssistance}
                          setJourneysExpanded={
                            this.props.setReturnJourneysExpanded
                          }
                          auth={this.props.auth}
                          showInfoPopup={this.props.showInfoPopup}
                          subJourneysExpanded={journey.subJourneysExpanded}
                          displayDisruptionsModal={
                            this.props.displayDisruptionsModal
                          }
                          toggleDisruptionsModal={
                            this.props.toggleDisruptionsModal
                          }
                          setInitialViewPort={this.setInitialViewPort}
                          terminateLegAnimation={
                            this.props.terminateLegAnimation
                          }
                          setTerminateLegAnimation={
                            this.props.setTerminateLegAnimation
                          }
                          toggleDirectionsModal={
                            this.props.toggleDirectionsModal
                          }
                          setSelectedLegIndex={this.props.setSelectedLegIndex}
                          setAlertsButton={this.props.setAlertsButton}
                          alertsButtonDisabled={this.props.alertsButtonDisabled}
                        />
                      )
                  )
                : ""}
            </ol>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default JourneyCollection;
